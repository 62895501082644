.booking-agree-checkbox {
	display: flex;
	.expand {
		color: #242424;
		display: flex;
		align-items: center;
		padding-right: 2rem;
		.expand-icon {
			width: 18px;
			height: 18px;
		}
	}

	.checkbox-label {
		margin-right: 0;
		.required {
			color: var(--primary-color);
		}
	}
	.check-icon {
		width: 24px;
		height: 24px;
	}
}
