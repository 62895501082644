.seperator-container {
	height: 8px;
	width: 100%;
	background-color: #f1f2f3;
	margin: 24px 0;
}

.seperator-container-inline {
	width: 90%;
	margin: 24px auto;
	border-top: 0.1px solid gray;
}
