.order-sheet-footer-container {
	padding: 25px 0 0;
	border-top: 1px solid #e6e6e6;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: var(--max-width);
	box-sizing: border-box;
	background: white;
	.info-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 25px;
		padding: 0 16px;
		.label {
			font-size: 18px;
			font-weight: 600;
			color: var(--gray-800);
			line-height: 23px;
		}
		.value {
			font-size: 18px;
			line-height: 23px;
			color: var(--gray-800);
			font-weight: 600;
		}
	}

	.action-container {
		display: flex;
		.menu-add-button {
			color: #272A2D;
			background-color: white;
			border-top: 1px solid #739FF8;
			border-right: 1px solid #739FF8;
		}
	}
}

.order-sheet-footer-container-placeholder {
	height: 154px;
	width: 100%;
}
