.circle-image-item-wrapper {
	max-width: 85px;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 16px;

	&.clickable {
		cursor: pointer;
	}

	.circle-item-image-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 83px;
		height: 83px;
		border-radius: 60px;
		margin-bottom: 8px;

		.circle-item-image {
			width: 53px;
			height: 28px;
			margin: 0;
			box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
			background-color: #ffffff;
		}
	}

	.circle-item-information-wrapper {
		display: flex;
		flex-direction: column;
		align-items: center;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;

		.circle-item-brand-category {
			height: 12px;
			font-size: 10px;
			font-weight: 500;
			text-align: left;
			color: var(--gray-500);
			margin-bottom: 8px;
		}

		.circle-item-brand-name {
			font-size: 14px;
			font-weight: 600;
			text-align: center;
			word-break: keep-all;
			color: var(--gray-900);
			margin-bottom: 8px;
		}
	}
}
