.time-selector-container {
	display: flex;
	flex-wrap: nowrap;
	overflow-x: scroll;

	&::-webkit-scrollbar {
		display: none;
	}

	.time-box {
		min-width: 5rem;
		height: 2.1875rem;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-right: 0.5rem;
		border: 0.0625rem solid var(--gray-400);
		border-radius: 0.25rem;
		box-sizing: border-box;
		background: var(--gray-000);
		color: var(--gray-500);
		font-weight: 500;
		white-space: nowrap;

		&:first-child {
			margin-left: 1rem;
		}

		&:last-child {
			margin-right: 1rem;
		}

		&.selected {
			color: var(--gray-900);
			border: 0.0625rem solid var(--primary-color);
			background: var(--primary-alpha-color);
		}
	}
}
