.search-bar-container {
	display: flex;
	justify-content: space-between;
	padding: 0 0.25rem;
	.search-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		.icon {
			height: 1.25rem;
		}
	}
	.back-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 40px;
		.icon {
			width: 13px;
			height: 13px;
		}
	}

	.search-container {
		width: 100%;
		position: relative;
		z-index: 100;

		.input-container {
			position: relative;
			display: flex;
			align-items: center;
			.search-input {
				width: 100%;
				height: 2.75rem;
				line-height: 2.75rem;
				font-size: 0.875rem;
				font-weight: 500;
				margin: 0;
				border: none;
				outline: none;
				caret-color: black;
				padding-left: 0.5rem;
				&::placeholder {
					font-weight: normal;
					color: #bec2c7;
				}
			}
		}

		.clear-button {
			.icon {
				width: 13px;
				height: 13px;
			}
		}
	}
}
