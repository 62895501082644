.shortcut-container {
	display: flex;
	overflow: scroll;
	margin-bottom: 18px;
	a {
		text-decoration: none;
		color: black;
	}
	.shortcut {
		font-size: 12px;
		line-height: 15px;
		padding: 9px 13px;
		margin-right: 4px;
		border: 1px solid #c1c1c1;
		border-radius: 20px;
		min-width: max-content;
		&:first-child {
			margin-left: 16px;
		}
	}
	&::-webkit-scrollbar {
		display: none;
	}
}
