.search-history-container {
	margin: 1rem 1rem;

	.search-title {
		display: flex;
		justify-content: space-between;
		color: #575b63;
		padding-bottom: 16px;
		.clear-all {
			color: var(--gray-600);
		}
	}

	.search-data {
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		height: 46px;
		.clear-button {
			width: 1.875rem;
			height: 106px;
			position: absolute;
			right: 0;
			top: 0;
		}
	}
	.search-icon-container {
		display: flex;
		align-items: center;
		.search-icon {
			width: 1.25rem;
			height: 1.25rem;
			margin-right: 0.5rem;
		}
	}
	.clear-icon-container {
		display: flex;
		align-items: center;
		.search-date {
			color: #bbbbbc;
		}
		.clear-icon {
			width: 1.25rem;
			height: 1.25rem;
		}
	}
}
