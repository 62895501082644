.menu-selector-container {
	margin: 0 20px 2rem;

	.menu-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 8px;
		padding: 13px 20px 14px;
		border: 1px solid var(--primary-color);
		border-radius: 4px;
		
		.label-container {
			.name {
				margin-bottom: 0.375rem;
			}
			.price {
				margin-top: 0.125rem;
				font-weight: 600;
				color: var(--gray-900);
			}
		}
		.select-container {
			.select-menu-box {
				@include body_14_m;
				height: 2.5rem;
				margin-left: 0.75rem;
				padding: 0 32px 0 0;
				outline: 0;
				border: none;
				-webkit-appearance: none;
				background: url(/assets/icon/dropdown.svg) no-repeat right 0 center;
				background-size: 1.25rem 1.25rem;
				color: var(--gray-900);
			}
		}
	}
}
