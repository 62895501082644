.image-area {
	position: relative;
	width: 100%;
	height: 185px;
	flex-grow: 0;
	border-radius: 5px;
	overflow: hidden;
	user-select: none;

	.image {
		width: 100%;
		height: 100%;
	}

	.live-information-wrapper {
		&:after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: linear-gradient(
				to top,
				rgba(0, 0, 0, 0.64),
				rgba(0, 0, 0, 0),
				rgba(0, 0, 0, 0)
			);
		}

		.live-target-mall-name {
			position: absolute;
			left: 16px;
			bottom: 36px;
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			font-size: 20px;
			font-weight: bold;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			z-index: 1;
		}

		.live-description {
			position: absolute;
			left: 16px;
			bottom: 16px;
			margin: 0;
			text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: #ffffff;
			z-index: 1;
		}
	}
}
