.payment-info-container {
	.total {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		padding: 16px;
		border-bottom: 1px solid #e6e6e6;
	}
	.paid {
		font-size: 16px;
		font-weight: bold;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		margin-top: 18px;
		padding: 0 16px;
		.value {
			color: #d30b17;
		}
	}
	.type {
		font-size: 14px;
		line-height: 24px;
		display: flex;
		justify-content: space-between;
		margin-top: 6px;
		padding: 0 16px;
	}
}
