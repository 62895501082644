.native-select-container {
	margin-bottom: 24px;

	.MuiFormLabel-root {
		.MuiFormLabel-asterisk {
			line-height: 23px;
			color: var(--voucher-accent-color);
		}
	}

	.MuiFormControl-root {
		width: 100%;
		height: 48px;
		border-radius: 4px;
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: #000000;

		.MuiInputBase-root {
			position: relative;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 22px;
			letter-spacing: -0.3px;
			border-radius: 4px;
			border: solid 1px var(--gray-300);
			background-color: var(--voucher-white-color);
			height: 100%;

			.MuiOutlinedInput-input {
				height: 48px;
				padding: 11px 0 11px 12px;
				border-radius: 2px;
				border: solid 1px var(--gray-300);
				background-color: #ffffff;
			}

			.MuiNativeSelect-root {
				&.not-selected {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: -0.3px;
					color: #a5a9b2;
				}

				&.selected {
					font-style: normal;
					font-weight: normal;
					font-size: 14px;
					line-height: 22px;
					letter-spacing: -0.3px;
					color: var(--black-000);
				}
			}

			.MuiNativeSelect-select {
				height: 100%;
				padding-left: 16px;
				box-sizing: border-box;

				&:focus {
					background-color: unset;
				}
			}
		}

		.MuiInput-underline {
			&:before,
			&:after {
				content: unset;
			}
		}
	}

	.default {
		.Mui-focused {
			border: solid 1px var(--voucher-primary-color);
		}
	}

	.success {
		.Mui-focused {
			border: solid 1px var(--functional-color-positive);
		}
	}

	.fail {
		.Mui-focused {
			border: solid 1px var(--functional-color-negative);
		}
	}

	.label {
		height: 24px;
		flex-grow: 0;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 23px;
		letter-spacing: normal;
		text-align: left;
		color: var(--gray-900);
		margin-bottom: 8px;
	}

	.required {
		font-size: 16px;
		color: var(--black-000);
	}
}
