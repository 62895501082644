.voucher-selection-modal {
	position: fixed;
	width: 100vw;
	height: 100vh;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	background-color: rgba(0, 0, 0, 0.4);
	overflow: hidden;

	.modal-container {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		max-height: 70vh;
		width: 100%;
		background: #fff;
		margin: 0 auto;
		border-radius: 4px;
		max-width: 313px;

		.modal-header {
			color: #555;
			display: flex;
			justify-content: center;
			align-items: center;
			height: 44px;
			font-size: 14px;
			border-bottom: 1px solid #ebebeb;
			position: sticky;
			z-index: 100;
			background-color: #fff;
		}

		.modal-body {
			overflow-y: scroll;
			max-height: calc(70vh - 44px - 52px);

			&::-webkit-scrollbar-thumb {
				background: #393812;
				-webkit-border-radius: 1ex;
				-webkit-box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.75);
			}

			.mall-list-item {
				color: #0c0c0c;
				display: flex;
				align-items: center;
				justify-content: space-between;
				padding: 10px 16px;
				line-height: 16px;
				font-size: 16px;
				border-bottom: 1px solid #f5f5f5;
				cursor: pointer;

				.mall-name {
					width: 64vw;
					text-overflow: ellipsis;
					overflow: hidden;
					white-space: nowrap;
					height: 16px;
				}

				.move-icon-wrapper {
					width: 8px;

					.right-arrow {
						width: 24px;
						height: 24px;
					}
				}
			}
		}

		.modal-footer {
			display: flex;
			justify-content: center;
			align-items: center;
			background-color: #363a3a;
			color: #fff;
			font-size: 14px;
			height: 52px;
			border-radius: 0 0 4px 4px;
			position: sticky;
			cursor: pointer;
			user-select: none;

			.close-button {
				background-color: #363a3a;
				color: #fff;
				border: 0;
				cursor: pointer;
			}
		}
	}
}
