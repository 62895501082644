.pickup-info-container {
	padding: 24px 16px 0;
	.status-text {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #47a000;
		margin-bottom: 4px;
	}
	.mall-name {
		font-weight: bold;
		font-size: 16px;
		line-height: 24px;
		margin-bottom: 20px;
		svg {
			margin-left: 10px;
			width: 5px;
			height: 10px;
		}
	}

	.row {
		display: flex;
		margin-bottom: 6px;
		.label {
			min-width: 60px;
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: #6f6f6f;
		}

		.value {
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: #6f6f6f;
		}
	}
}
