.month-handler {
	display: flex;
	justify-content: space-between;
	align-items: center;

	.date-text {
		font-weight: 600;
	}
	.action-container {
		display: flex;
		.prev-button {
			margin-right: 0.875rem;
		}
		.icon {
			width: 2.25rem;
			height: 2.25rem;
			vertical-align: middle;
		}
	}
}
