.voucher-item-wrapper {
	padding-bottom: 24px;
	border-bottom: 2px solid var(--voucher-border-gray);
	padding: 24px 16px;

	&:last-child {
		border-bottom: 0;
	}

	.voucher-information-wrapper {
		display: flex;
		align-items: flex-start;
		position: relative;
		margin-bottom: 16px;

		.thumbnail {
			width: 64px;
			height: 64px;
			margin-right: 10px;
			border-radius: 60px;
		}

		.voucher-information {
			.voucher-name {
				display: block;
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				color: #0c0c0c;
				margin-bottom: 6px;
			}

			.voucher-use-information {
				font-size: 12px;
				line-height: 14px;
				color: #919191;

				p {
					margin: 0;
					margin-bottom: 6px;

					&:last-child {
						margin: 0;
					}
				}
			}
		}

		.voucher-controller-wrapper {
			display: flex;
			align-items: center;
			position: absolute;
			user-select: none;
			right: 0;
			top: 0;
			bottom: 0;

			.voucher-control-button {
				width: 64px;
				height: 23.5px;
				box-sizing: border-box;
				border-radius: 33px;
				background-color: var(--voucher-white-color);
				cursor: pointer;

				.text {
					font-style: normal;
					font-weight: 600;
					font-size: 12px;
					line-height: 14px;
					letter-spacing: -0.75px;
				}

				&.using {
					border: 1px solid #008ad5;
					color: #008ad5;
				}

				&.cancel {
					border: 1px solid var(--voucher-accent-color);
					color: var(--voucher-accent-color);
				}
			}
		}
	}

	.button-group {
		display: flex;
		align-items: center;
		box-sizing: border-box;
		border-radius: 3px;
		font-style: normal;
		font-weight: normal;
		font-size: 12px;
		line-height: 14px;
		text-align: center;
		letter-spacing: -0.5px;

		.share {
			width: 100%;
			background: var(--voucher-primary-color);
			border: 1px solid var(--voucher-primary-color);
			padding: 13px 0;
			border-radius: 3px 0 0 3px;
			cursor: pointer;

			.text {
				color: var(--voucher-white-color);
			}
		}

		.re-purchase {
			width: 100%;
			background: var(--voucher-white-color);
			border: 1px solid var(--voucher-primary-color);
			padding: 13px 0;
			border-radius: 0 3px 3px 0;
			cursor: pointer;

			.text {
				color: var(--voucher-primary-color);
			}
		}
	}
}
