.payment-loader-container {
	display: flex;
	min-width: var(--max-width);
	height: 52px;
	align-items: center;
	justify-content: center;
	position: fixed;
	bottom: 0;
	z-index: 10;
}
