.order-memo-container {
	padding: 0 16px;
	.input {
		outline: none;
		border: 1px solid #c1c1c1;
		border-radius: 4px;
		width: 100%;
		font-size: 14px;
		line-height: 17px;
		padding: 12px;
		box-sizing: border-box;
	}
}
