.payment-container {
	display: flex;
	flex-direction: column;
	user-select: none;

	& > h1 {
		@include title_18_m;
		margin: 0 0 16px;
		color: #242629;
	}

	& > div {
		display: flex;
		flex-direction: column;

		& > *:not(:last-of-type) {
			margin-bottom: 20px;
		}
	}
}
