.history-item-container {
	display: flex;
	flex-direction: column;
	padding: 16px;
	border-bottom: 1px solid #f1f1f1;
	.history-top-box {
		display: flex;
		justify-content: space-between;

		.history-type {
			font-size: 14px;
			line-height: 21px;
		}

		.history-amount {
			font-weight: 600;
			font-size: 14px;
			line-height: 21px;
			&.plus {
				color: #0075ff;
			}
		}
	}

	.history-time {
		font-size: 12px;
		line-height: 17px;
		color: #6f6f6f;
	}
}
