.my-page-container {
	.user-info-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 20px 16px 0;
		font-style: normal;

		.user-info-view {
			font-weight: bold;
			font-size: 20px;
			line-height: 26px;
			color: var(--gray-900);
		}

		.logout-button {
			cursor: pointer;
			height: 23.5px;
			border: 1px solid rgba(17, 44, 75, 0.6);
			box-sizing: border-box;
			border-radius: 33px;
			font-weight: 600;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: -0.75px;
			color: rgba(17, 44, 75, 0.6);
			background-color: #ffffff;
		}
	}
	.voucher-products {
		.voucher-products-title {
			padding: 16px;
			margin: 0;
			font-style: normal;
			font-weight: bold;
			font-size: 16px;
			line-height: 24px;
			color: var(--black-200);
			border-bottom: 2px solid var(--voucher-border-gray);
		}

		.voucher-items {
			padding: 16px;
			.voucher-item {
			}
		}

		.none-items {
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			text-align: center;
			color: #919191;
			padding: 24px 0;
			margin: 0;
		}
	}
}
