.account-card-container {
	margin: 14px 16px;
	padding: 15px;
	background-color: #f5f5f5;
	border-radius: 4px;
	.card-name {
		font-weight: bold;
		font-size: 12px;
		width: 100%;
		margin-bottom: 8px;
	}
	.card-left-text {
		font-size: 14px;
		text-align: right;
		width: 100%;
		margin-bottom: 4px;
	}
	.card-left-amount {
		font-size: 16px;
		font-weight: 600;
		text-align: right;
		width: 100%;
	}
}
