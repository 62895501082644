.booking-info-view-container {
	display: flex;
	flex-direction: column;
	margin: 20px;

	.mall-name {
		@include title_18_m;
		color: #0c0d0e;
		word-spacing: initial;
		margin-bottom: 16px;
	}

	&.mini {
		margin-bottom: 2rem;

		.mall-name {
			@include title_18;
			margin-bottom: 8px;
		}
	}

	.detail-info {
		&-box {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			margin-bottom: 20px;
			padding: 24px 0;
			background: #ffffff;
			box-shadow: 0 2px 6px 1px rgba(103, 143, 179, 0.2),
			0 1px 3px rgba(103, 143, 179, 0.2);
			border-radius: 8px;

			div {
				display: flex;
				flex-direction: column;
				text-align: center;

				label {
					@include body_14_2;
					color: var(--gray-900);
					margin-bottom: 8px;
				}

				span {
					font-weight: 500;
					font-size: 14px;
					line-height: 14px;
					letter-spacing: -0.003em;
					color: var(--primary-color);
				}
			}
		}

		&-text {
			@include body_14;
			color: var(--gray-500);
		}
	}
}
