.complete-container {
	height: calc(100vh - 50px);
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	.icon {
		margin-bottom: 24px;
	}
}
