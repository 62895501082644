.voucher-home-item-container {
	margin-right: 1rem;

	&:first-child {
		padding-left: 1rem;
	}

	&:last-child {
		padding-right: 1rem;
	}

	.mall-food {
		margin-top: 0.625rem;
	}
	.mall-name {
		margin-top: 0.125rem;
	}
	.amount {
		margin-top: 0.375rem;
		text-decoration: line-through;
		color: var(--gray-400);
	}
	.sale-amount {
		font-weight: 600;
		.sale-percent {
			color: var(--functional-color-negative);
			margin-right: 0.375rem;
		}
	}
	.mall-image {
		height: 216px;
		width: 216px;
		border-radius: 0.25rem;
	}
}
