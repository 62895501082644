.subtitle {
	@include title_16_m2;
	display: flex;
	gap: 4px;
	flex-direction: row-reverse;
	margin-bottom: 24px;
	word-spacing: initial;
	
	&.mini {
		@include title_16_m;
		margin-bottom: 16px;
	}

	.firebrick {
		color: var(--blue);
	}
}
