.additional-info-form-container {
	padding: 0 1rem;
	.additional-item {
		padding: 0 0 2rem;
		.text-additional-info {
			position: relative;
			.input {
				outline: none;
				border: 0.0625rem solid #c1c1c1;
				border-radius: 0.25rem;
				width: 100%;
				padding: 0.75rem;
				box-sizing: border-box;
				position: relative;
				&:focus {
					& + .clear-button {
						opacity: 1;
					}
				}

				&:placeholder-shown {
					& + .clear-button {
						display: none;
					}
				}
			}
			.clear-button {
				width: 1.25rem;
				height: 1.25rem;
				position: absolute;
				right: 0.625rem;
				top: 0.625rem;
				opacity: 0;
				transition-property: all;
				transition-duration: 1s;
				transition-timing-function: cubic-bezier(0, 1, 0.5, 1);

				.icon {
					width: 1.25rem;
					height: 1.25rem;
				}
			}
		}
		.radio-container {
			display: flex;
		}
		.additional-select {
			height: 2.5rem;
			border: 0.0625rem solid var(--gray-400);
			border-radius: 0.125rem;
			-webkit-appearance: none;
			outline: 0;
			width: 100%;
			padding-left: 0.75rem;
			background: url(/assets/icon/chevronDown.svg) 90% 50% no-repeat;
			background-size: 1.25rem 1.25rem;
		}

		.description {
			display: flex;
			align-items: center;
			padding: 0.5rem 0;
			.icon {
				width: 0.75rem;
				height: 0.75rem;
				margin-right: 0.5rem;
			}
		}
	}
}
