.booking-footer-container {
	background: var(--primary-color);
	color: var(--primary-text-color) !important;
	text-align: center;
	position: var(--partner-footer-position);
	bottom: 0;
	margin: 0;
	width: 100%;
	max-width: var(--max-width);
	height: 3.25rem;
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 3;
	&.disabled {
		background: var(--gray-100);
	}
}
