.sale-notice-container {
	padding: 0.75rem 1rem;
	margin: 0 1rem;
	background: var(--gray-100);
	.title {
		color: var(--accent-color);
		margin-bottom: 0.75rem;
		font-weight: 600;
	}
}
