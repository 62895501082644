.order-memo-container {
	padding: 0 16px 8px;
	.subtitle {
		flex-direction: row;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: -0.36px;
		margin-bottom: 20px;
	}
	.input {
		outline: none;
		border: 1px solid #D6D8DB;
		border-radius: 2px;
		width: 100%;
		font-size: 14px;
		line-height: 18px;
		padding: 13px;
		box-sizing: border-box;
	}
}
