.title {
	height: 25px;
	margin-bottom: 16px;
	font-size: 21px;
	font-weight: 600;
	font-stretch: normal;
	font-style: normal;
	line-height: normal;
	letter-spacing: normal;
	text-align: left;
	color: var(--black-200);
}
