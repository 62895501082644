.gyeongjuro-action-button {
	border-radius: 0;
	box-sizing: border-box;
	width: calc(100%);
	margin: 0 16px;
	height: 52px;
	font-size: 16px;
	&.MuiButton-contained {
		color: white;
	}
	&.MuiButton-root {
		border-radius: 0;
	}
	&.Mui-disabled {
		&.MuiButton-contained {
			color: white;
			background: #D6D8DB;
		}
	}
	&.two-button {
		margin: 0;
	}
}
