.account-info-container {
	padding: 24px 24px;
	margin: 40px 32px;
	border-radius: 8px;
	background: #fafafa;
	.box {
		display: flex;
		justify-content: space-between;
		margin: 18px 0;

		.label {
			font-size: 14px;
		}

		.value {
			font-size: 14px;
			font-weight: 600;
		}
	}
}
