.select-voucher-mall-container {
	.voucher-radio-group {
		max-height: calc(100vh - 108px);
		flex-wrap: unset;
		overflow: auto;
	}
	.radio-box {
		margin: 0 1rem;
		padding: 0.5rem 0;
		border-bottom: 1px solid var(--gray-200);
		&:last-child {
			border-bottom: 0;
		}
		.radio-label {
			.MuiTypography-body1 {
				color: var(--gray-800);
				font-size: 14px;
				line-height: 18px;
			}
		}
	}
	.footer-button-container {
		display: flex;
		width: 100%;
		justify-content: space-between;
		border-top: 1px solid var(--gray-200);
		position: absolute;
		z-index: 1;
		bottom: 0;
		.footer-button {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 100%;
			padding: 1rem;
			background: white;
			&.selected {
				background-color: var(--primary-color);
				color: white;
			}
			&.disabled {
				color: white;
				background-color: var(--gray-100);
			}
		}
	}
}
