.mall-pickup-description-container {
	padding: 24px 16px 0;
	.row {
		display: flex;
		.label {
			min-width: 60px;
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: #6f6f6f;
		}
		.value {
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: var(--gray-800);
		}
	}
}
