.request-form-container {
	margin: 0 20px 4rem;

	.request-textarea {
		@include small_2;
		width: 100%;
		min-height: 116px;
		height: max-content;
		margin-bottom: 12px;
		padding: 13px 16px;
		box-sizing: border-box;
		border: 1px solid var(--gray-300);
		border-radius: 4px;
		resize: none;
		transition: border 200ms;
		word-break: break-all;
		color: inherit;

		&:focus {
			outline: 0;
			border: 1px solid var(--primary-color);
		}

		&::placeholder {
			color: var(--gray-500);
		}
	}

	.description {
		word-spacing: initial;
		
		ul {
			@include small_2;
			margin: 0 0 0 20px;
			padding: 0;
			color: var(--gray-500);
		}
	}
}
