.footer-container {
	border-top: 1px solid #e6e6e6;
	display: block;

	div {
		text-align: center;
		vertical-align: middle;
	}

	.logo-container {
		svg {
			width: 95px;
			height: 12px;
		}
		cursor: pointer;
		margin-top: 0.8rem;
		font-size: 0.8rem;
		padding: 0 0.8rem;
		color: #8a8a8a;
		font-weight: 300;
		display: flex;
		justify-content: center;
		align-items: center;

		.logo {
			height: 12px;
			margin-right: 8px;
		}
	}

	.title-container {
		display: flex;
		justify-content: center;
		align-items: center;
		.arrow {
			height: 7px;
			vertical-align: middle;
			cursor: pointer;
			&.reverse {
				transform: rotate(180deg);
			}
		}

		.footer-title {
			font-size: 0.8rem;
			padding: 0 0.8rem;
			color: #8a8a8a;
			font-weight: 300;
		}
	}

	.terms-container {
		font-size: 0.8rem;
		margin-bottom: 1.3rem;
		color: #8a8a8a;
		font-weight: 300;
		display: flex;
		justify-content: center;
		align-items: center;
		cursor: pointer;

		div {
			padding: 0.2rem 0.3rem;
		}
	}
	.info-container {
		color: #8a8a8a;
		max-height: 100%;
		font-size: 0.6rem;
	}
}
