.menu-confirm-container {
	padding: 16px;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: var(--max-width);
	box-sizing: border-box;
	background: white;
	.count-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		.icon-container {
			display: flex;
			align-items: center;

			.icon {
				svg {
					width: 28px;
					height: 28px;
				}
			}

			.count {
				font-size: 16px;
				line-height: 20px;
				color: var(--gray-800);
				margin: 0 22px;
			}
		}

		.price-container {
			font-size: 24px;
			line-height: 30px;
			color: var(--gray-800);
			font-weight: 800;
		}
	}
}

.menu-confirm-container-placeholder {
	height: 132px;
	width: 100%;
}
