.payment-agreement-container {
	margin: 0 1rem 5rem;
	.check-icon {
		width: 22px;
		height: 22px;
	}
	.separator-line {
		background-color: var(--gray-200);
		width: 100%;
		margin: 1.25rem 0;
		height: 1px;
		box-sizing: border-box;
	}
	.checkbox-all {
		.MuiTypography-body1 {
			font-weight: 600;
			font-size: 16px;
			line-height: 21px;
			color: var(--gray-900);
		}
	}
}
