.business-hours-container {
	font-size: 0.875rem;
	font-weight: 500;
	position: relative;
	padding: 0 1rem;
	margin-top: 2rem;
	.weekday {
		margin-right: 0.5rem;
	}
}
