.modal {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background-color: rgba(36, 38, 41, 0.4);

	.modal-footer {
		display: flex;
	}
}

.modal.openModal {
	display: flex;
	align-items: center;
	justify-content: center;

	.modal-footer {
		button {
			width: 100%;
			border: 0;
			padding: 1rem;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.03em;
		}
	}
}
