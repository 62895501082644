.dim-bottom-sheet {
	// dim
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;
	height: 100%;
	overflow: hidden;
	z-index: 10;
	background: rgba(0, 0, 0, 0.4);
}

.voucher-refund-wrapper {
	.voucher-refund-content {
		ul {
			margin: 20px 0;
			padding: 0;

			li {
				list-style: none;
				margin: 24px 16px 0;

				&:first-of-type {
					margin-top: 20px;
				}

				&:not(:last-of-type) {
					border-bottom: 1px solid #DEE1E5;
				}

				h3 {
					margin: 0;
					font-weight: 700;
					font-size: 15px;
					line-height: 18px;
					color: #222222;
				}

				.voucher-list-wrapper {
					margin-block: 24px;
					dl {
						margin: 16px 0;
						display: flex;
						align-items: center;
						justify-content: space-between;

						dt {
							font-weight: 500;
							font-size: 14px;
							line-height: 20px;
							letter-spacing: -0.5px;
							color: #272A2D;
						}

						dd {
							font-weight: 600;
							font-size: 14px;
							line-height: 18px;
							color: #393D42;
						}
					}

					.icon-group {
						display: flex;
						align-items: center;
						justify-content: center;
						height: 34px;
						background: var(--primary-text-color);
						box-sizing: border-box;
						border-radius: 4px;

						.icon-box {
							width: 34px;
							height: 34px;
							background: var(--primary-text-color);
							margin: 0;

							.icon {
								width: 34px;
								height: 34px;

								&.active {
									cursor: pointer;
								}
							}
						}

						.value {
							width: 34px;
							height: 34px;
							display: flex;
							align-items: center;
							justify-content: center;
							font-style: normal;
							font-weight: 500;
							font-size: 14px;
							line-height: 18px;
							text-align: center;
							letter-spacing: -0.5px;
							color: var(--black-000);
							border: 1px solid var(--gray-400);
							border-left: 0;
							border-right: 0;
							box-sizing: border-box;
						}
					}
				}

			}
		}
	}
}

.voucher-bottom-sheet-wrapper.footer-wrapper {
		width: 100vw;
		max-width: 360px;
		position: fixed;
		bottom: 0;
		padding: 0;
		background: transparent;
		z-index: 11;

		&.mobile {
			max-width: unset;
		}

		// 빕스 예약상품권 유의사항
		&.ticket-notes-wrapper {
			.voucher-ticket-notes-content {
				min-height: 236px;
				padding-inline: 16px;
				margin-block: 20px;

				h3 {
					margin: 0 0 12px;
					font-weight: 700;
					font-size: 18px;
					line-height: 22px;
					display: flex;
					align-items: center;
					color: #272A2D;
				}

				p {
					margin: 0;
					white-space: pre-line;
					font-weight: 500;
					font-size: 14px;
					line-height: 21px;
					color: #393D42;
				}
			}
		}

		.voucher-select-popup {
			display: none;
			background-color: var(--primary-text-color);
			box-shadow: 0px -2px 10px rgba(207, 211, 217, 0.28);
			border-radius: 16px 16px 0px 0px;

			&.open {
				display: block;
			}

			.close-button {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 20px;
				padding-top: 4px;
				cursor: pointer;

				.close-arrow-image {
					height: 8px;
				}
			}

			&-title {
				padding-block: 18px;
				text-align: center;
				font-weight: 700;
				font-size: 16px;
				line-height: 19px;
				color: #272A2D;
				border-bottom: 1px solid #DEE1E5;
			}

			.voucher-items {
				max-height: 273px;
				overflow: scroll;
				padding: 0 16px;

				.voucher-item {
					width: 100%;
					padding: 20px 0;
					border-bottom: 1px solid var(--gray-300);
					box-sizing: border-box;

					&:last-child {
						border-bottom: 0;
					}

					.voucher-product-name {
						font-style: normal;
						font-weight: 500;
						font-size: 14px;
						line-height: 20px;
						letter-spacing: -0.5px;
						color: var(--gray-900);
						margin: 0;
						margin-bottom: 32px;
					}

					.voucher-information {
						display: flex;
						align-items: flex-end;
						justify-content: space-between;

						.icon-group {
							display: flex;
							align-items: center;
							justify-content: center;
							height: 34px;
							background: var(--primary-text-color);
							box-sizing: border-box;
							border-radius: 4px;

							.icon-box {
								width: 34px;
								height: 34px;
								background: var(--primary-text-color);
								margin: 0;

								.icon {
									width: 34px;
									height: 34px;

									&.active {
										cursor: pointer;
									}
								}
							}

							.value {
								width: 34px;
								height: 34px;
								display: flex;
								align-items: center;
								justify-content: center;
								font-style: normal;
								font-weight: 500;
								font-size: 14px;
								line-height: 18px;
								text-align: center;
								letter-spacing: -0.5px;
								color: var(--black-000);
								border: 1px solid var(--gray-400);
								border-left: 0;
								border-right: 0;
								box-sizing: border-box;
							}
						}

						.voucher-price-wrapper {

							.voucher-origin-price {
								font-style: normal;
								font-weight: normal;
								font-size: 12px;
								line-height: 15px;
								text-align: right;
								letter-spacing: -0.5px;
								color: var(--gray-400);
								margin: 0;
								margin-bottom: 4px;
							}

							.voucher-saled-price {
								font-weight: 600;
								font-size: 16px;
								line-height: 20px;
								text-align: right;
								letter-spacing: -0.5px;
								margin: 0;

								.discount-rate {
									color: var(--quaternary-color);
									margin-right: 6px;
								}

								.actual-purchase-price {
									color: var(--gray-900);
								}
							}
						}
					}
				}
			}

			.no-voucher-items {
				text-align: center;
				color: var(--gray-400);
				font-size: 14px;

				.description {
					margin: 24px;
				}
			}
		}

		.voucher-total-purchase-price-wrapper {
			padding: 20px 16px;
			background-color: var(--gray-200);

			.total-information {
				display: flex;
				align-items: center;
				justify-content: space-between;
				height: 16px;
				margin-bottom: 15px;

				&:last-child {
					margin: 0;
				}

				.total-information-title {
					font-style: normal;
					font-weight: 500;
					font-size: 14px;
					line-height: 18px;
					letter-spacing: -0.5px;
					color: var(--gray-900);
				}

				.total-number {
					font-style: normal;
					font-weight: 600;
					font-size: 14px;
					line-height: 23px;
					text-align: right;
					letter-spacing: -0.5px;
					color: var(--gray-900);
				}

				.total-price {
					font-style: normal;
					font-weight: 600;
					font-size: 18px;
					line-height: 23px;
					text-align: right;
					letter-spacing: -0.5px;
					color: var(--gray-900);
				}
			}
		}

		.button-wrapper {
			height: 52px;
			user-select: none;

			& > button > span {
				font-weight: 500;
			}

			&.ticket-notes-button-wrapper {
				column-gap: 8px;
				border-top: 1px solid var(--gray-100);

				.cancel-button {
					width: 100%;
					height: 100%;
					cursor: pointer;
					border: 0;
					background: var(--gray-100);
					border-radius: 3px;

					.cancel-button-text {
						font-size: 18px;
						line-height: 20px;
						color: #000000;
					}
				}
			}

			&.share {
				padding: 8px 16px;
				display: flex;
				align-items: center;
				background: var(--voucher-white-color);

				.buy-button {
					border-radius: 3px;
					background-color: var(--primary-color);
				}
			}

			.share-button {
				height: 52px;
				margin-right: 8px;
				background: var(--gray-100);
				border-radius: 4px;
				border: 0;
				padding: 14px 21px;
				cursor: pointer;

				.icon {
					width: 24px;
					height: 24px;
				}
			}

			.buy-button {
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				height: 100%;
				background-color: var(--primary-color);
				cursor: pointer;
				border: 0;

				&:disabled {
					cursor: default;
					background-color: rgba(0, 117, 255, .3);
				}

				.buy-button-text {
					font-style: normal;
					font-size: 18px;
					line-height: 23px;
					display: flex;
					align-items: flex-end;
					text-align: center;
					letter-spacing: -0.5px;
					color: var(--primary-text-color);
				}
			}
		}
	}
