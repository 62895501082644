.menu-list-container {
	.button-container {
		padding: 16px;
		position: fixed;
		bottom: 0;
		background: white;
		width: 100%;
		max-width: var(--max-width);
		box-sizing: border-box;
	}
}
