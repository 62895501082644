.deposit-notice-container.share-component {
	padding: 0 1rem;
	.notice-box {
		margin-bottom: 2.5rem;
		.description {
			.noshow {
				color: #ff360a;
			}
		}
	}
}
