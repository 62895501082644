@mixin title_18 {
	font-weight: 500;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: -0.3px;
}

@mixin title_18_m {
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: -0.3px;
}

@mixin title_18_sb {
	font-weight: 700;
	font-size: 18px;
	line-height: 20px;
	letter-spacing: -0.3px;
}

@mixin title_16_m {
	font-weight: 500;
	font-size: 16px;
	line-height: 16px;
	letter-spacing: -0.3px;
}

@mixin title_16_m2 {
	font-weight: 500;
	font-size: 16px;
	line-height: 18px;
	letter-spacing: -0.3px;
}

@mixin body_14 {
	font-weight: 400;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.03em;
}

@mixin body_14_m {
	font-weight: 500;
	font-size: 14px;
	line-height: 18px;
	letter-spacing: -0.03em;
}

@mixin small_2 {
	font-weight: 400;
	font-size: 12px;
	line-height: 18px;
	letter-spacing: -0.3px;
}

@mixin body_14_2 {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	letter-spacing: -0.3px;
}

@mixin body_13 {
	font-weight: 400;
	font-size: 13px;
	line-height: 20px;
	letter-spacing: -0.03em;
}

@mixin title_44 {
	font-weight: 700;
	font-size: 44px;
	line-height: 50px;
}

@mixin title_32 {
	font-weight: 700;
	font-size: 32px;
	line-height: 42px;
}

@mixin title_32_sb {
	font-weight: 700;
	font-size: 16px;
	line-height: 16px;
}

@mixin title_32_b {
	font-weight: 700;
	font-size: 32px;
	line-height: 42px;
}

@mixin subtitle {
	font-weight: 400;
	font-size: 30px;
	line-height: 40px;
}

@mixin subtitle_m {
	font-weight: 500;
	font-size: 30px;
	line-height: 40px;
}

@mixin subtitle_b {
	font-weight: 700;
	font-size: 30px;
	line-height: 40px;
}

@mixin body_28_sb {
	font-weight: 700;
	font-size: 28px;
	line-height: 36px;
}

@mixin body_28_b {
	font-weight: 700;
	font-size: 28px;
	line-height: 36px;
}

@mixin body_12 {
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
}

@mixin body_12_m {
	font-weight: 500;
	font-size: 12px;
	line-height: 12px;
	letter-spacing: -0.003em;
}

@mixin small_1 {
	font-weight: 400;
	font-size: 24px;
	line-height: 30px;
}

@mixin small_m {
	font-weight: 500;
	font-size: 24px;
	line-height: 36px;
	letter-spacing: -0.01em;
}

@mixin small_b {
	font-weight: 700;
	font-size: 24px;
	line-height: 36px;
}

@mixin s_small {
	font-weight: 400;
	font-size: 22px;
	line-height: 30px;
}

@mixin s_small_sb {
	font-weight: 700;
	font-size: 22px;
	line-height: 30px;
}

@mixin ss_small {
	font-weight: 400;
	font-size: 20px;
	line-height: 26px;
	letter-spacing: -0.01em;
}
