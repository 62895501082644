.footer-wrapper {
	position: relative;
	padding: 24px 16px 34px;
	background-color: #f5f7f8;

	.footer-business-menus,
	.footer-introduce-menus,
	.footer-service-center {
		list-style-type: none;
		margin: 0;
		padding: 0;
		padding-bottom: 48px;
	}

	.footer-service-center {
		.footer-service-center-header {
			display: flex;
			align-items: baseline;
			justify-content: space-between;
			margin-bottom: 12px;

			.footer-service-center-sales-time {
				font-size: 11px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: #aab4bf;
			}
		}
	}

	.footer-menu-title {
		height: 18px;
		font-size: 15px;
		font-weight: bold;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: var(--gray-900);
	}

	.footer-menu {
		height: 16px;
		margin-top: 16px;
		font-size: 13px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: var(--gray-800);
		user-select: none;

		span {
			cursor: pointer;
		}
	}

	.terms-wrapper {
		margin-bottom: 33px;

		.terms-items {
			display: flex;
			list-style-type: none;
			margin: 0px;
			padding: 0px;

			.terms-item {
				cursor: pointer;
				margin-right: 44px;
				font-size: 10px;
				font-weight: normal;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: rgba(17, 44, 75, 0.33);

				&:last-child {
					margin: 0;
				}
			}
		}
	}

	.information-wrapper {
		.information-company {
			margin-bottom: 8px;
			font-size: 9px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: rgba(17, 44, 75, 0.33);
		}

		.information-notice {
			font-size: 9px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: rgba(17, 44, 75, 0.33);
		}
	}
}
