.counter-option-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 8px;
	.option-name {
		font-size: 14px;
		line-height: 17px;
	}
	.counter-container {
		display: flex;
		justify-content: space-between;
		svg {
			width: 28px;
			height: 28px;
		}
		.count {
			padding: 0 14px;
			color: var(--gray-800);
			font-size: 14px;
			line-height: 28px;
		}
	}
}
