.booking-footer-container {
	width: 100%;
	max-width: var(--max-width);
	height: 3rem;
	display: flex;
	justify-content: center;
	align-items: center;
	position: var(--partner-footer-position);
	bottom: 0;
	z-index: 3;
	background-color: var(--primary-color);
	color: var(--primary-text-color) !important;
	text-align: center;
	font: {
		weight: 500;
		size: 16px;
	}

	line-height: initial;
	letter-spacing: -0.3px;

	&.disabled {
		background: var(--gray-200);
	}
}
