.action-button {
	border-radius: 4px;
	box-sizing: border-box;
	width: calc(100% - 32px);
	margin: 0 16px;
	height: 52px;
	font-size: 16px;
	&.Mui-disabled {
		color: #c1c1c1;
		background: #f1f1f1;
	}
	&.two-button {
		margin-right: 12px;
	}
}
