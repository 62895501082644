.missed-live-wrapper {
	width: 159px;
	margin-right: 10px;
	cursor: pointer;

	&:last-child {
		margin: 0;
	}

	.image {
		width: 159px;
		height: 199px;
		flex-grow: 0;
		margin: 0 0 8px;
		border-radius: 3px;
		background-color: #c4c4c4;
	}

	.brand-information {
		display: flex;
		flex-direction: column;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;

		.brand-category {
			font-size: 10px;
			font-weight: 500;
			text-align: left;
			color: #c1c4cc;
			margin-bottom: 5px;
		}

		.brand-name {
			font-size: 18px;
			font-weight: 600;
			text-align: left;
			color: #000000;
			margin-bottom: 5px;
		}

		.brand-description {
			font-size: 14px;
			font-weight: 500;
			text-align: left;
			color: #000000;
		}
	}
}
