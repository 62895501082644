.refund-policy-border-line {
	height: 1px;
	margin: 16px 0 0;
	border: 0;
	background-color: var(--gray-900);
}

table.refund-policy-table {
	@include body_12;
	width: 100%;
	word-spacing: initial;
	border-collapse: collapse;
	text-align: center;

	th,
	td {
		width: 50%;
		padding: 12px 0 13px;
		border-bottom: 1px solid #d6d8db;

		&:nth-of-type(odd) {
			border-right: 1px solid #d6d8db;
		}
	}

	thead {
		background: var(--gray-000);

		th {
			font-weight: inherit;
			color: var(--gray-900);
		}
	}

	tbody {
		td {
			color: var(--gray-800);
		}
	}
}
