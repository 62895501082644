.booking-agree-checkbox {
	margin-bottom: 16px;

	.MuiFormControlLabel-root {
		align-items: flex-start;

		.MuiCheckbox-root {
			padding-top: 2px;
			padding-bottom: 0;
		}
	}
}
