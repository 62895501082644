.menu-item-container {
	padding: 18px 16px;
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid #f1f1f1;
	.description-container {
		.menu-name {
			font-size: 14px;
			line-height: 17px;
			font-weight: bold;
			margin-bottom: 8px;
		}
		.menu-price {
			font-size: 14px;
			line-height: 17px;
		}
	}
	.image-container {
		height: 80px;
		img {
			border-radius: 4px;
			width: 80px;
			height: 80px;
		}
	}
}
