.main-container-mobile .mall-footer-container {
	font-size: 4.5vw!important;
	li::marker {
		font-size: 0.9em!important;
	}
}
.mall-footer-container {
	padding: 3.125em 1.25em 1em 1.25em;
	background: #efefef;
	a {
		color: inherit;
	}
	& > .footer_label {
		font-weight: 700;
		margin-bottom: 0.95em;
		line-height: 1em;
	}
	& > ul {
		padding: 0 0 0 1em;
		margin: 0 0 2.05em 0;
		font-weight: 400;

		//letter-spacing: -0.026em;
		& > li {
			font-size: 0.75em;
			margin-bottom: 0.55em;
			line-height: 1.45em;
			padding-left: 0.15em;
			letter-spacing: -.025em;
			word-break: keep-all;
			&::marker {
				font-size: 0.6em;
			}
		}
		img {
			height: 1em;
			transform: translateY(0.15em);
			&.kakao {
				transform: translateY(0.15em) scale(1.5);
				margin: 0 0.9em;
			}
			&.last {
				margin-left: -0.5em;
				margin-right: -0.8em;
			}
		}
	}
}
