.gyeongju-pickup-menu-container {
	display: flex;
	flex-direction: column;
	padding: 20px 16px;
	gap: 16px;
	border-bottom: 1px solid #F1F2F3;
	
	.row {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.menu-option-group-container {
		display: flex;
		flex-direction: column;
	}
	.menu-name {
		font-size: 16px;
		font-weight: 600;
		line-height: 21px;
	}
	.menu-delete-button {
		width: 20px;
		height: 20px;
		
		svg {
			width: 20px;
			height: 20px;
		}
	}
	.menu-price {
		color: #242629;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
		letter-spacing: -0.5px;
	}
}
