.reservation-preview-container {
	display: flex;
	flex-direction: column;
	margin: 20px 20px 40px;

	.info-box {
		list-style-type: '·';
		color: var(--gray-600);

		&:first-of-type {
			margin-top: 8px;
		}

		&:not(:last-of-type) {
			margin-bottom: 10px;
		}

		& > div {
			display: inline-block;
			position: relative;
			left: 8px;
		}

		.label {
			@include body_12_m;
			width: 55px;
			color: var(--gray-600);
			word-spacing: initial;
		}
		
		.value {
			@include body_12;
			color: var(--gray-900);
			word-spacing: initial;
		}
	}
}
