.confirm-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: calc(100% - 50px);
	svg {
		margin-bottom: 32px;
	}
	.good-job {
		display: flex;
		justify-content: center;
		flex-direction: column;
		align-items: center;
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
		margin-bottom: 50px;
	}

	.close-button {
		width: 100%;
		padding: 0 16px;
		box-sizing: border-box;
		margin-top: 32px;
	}
}
