.bulk-purchase-inquiry-container {
	position: relative;

	.bulk-purchase-inquiry-wrapper {
		padding: 14px 16px 24px;
		margin-bottom: 16px;

		.bulk-purchase-inquiry-description {
			margin-bottom: 48px;

			.bulk-purchase-inquiry-description-title {
				font-style: normal;
				font-weight: bold;
				font-size: 21px;
				line-height: 24px;
				color: var(--gray-900);
				margin: 0;
				margin-bottom: 18px;
			}
		}

		.bulk-purchase-inquiry-form {
			.input-container:last-of-type {
				margin: 0;
				margin-bottom: 8px;
			}

			.inquiry-content-count {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				text-align: right;
				letter-spacing: -0.3px;
				color: #a5a9b2;
				margin: 0;
			}
		}
	}

	.button-area {
		display: flex;
		align-items: center;
		justify-content: center;
		background: rgba(17, 44, 75, 0.2);
		width: 100%;
		height: 60px;
		cursor: pointer;
		user-select: none;
		position: relative;

		&.valid {
			background: var(--voucher-primary-color);
		}

		&.invalid {
			cursor: unset;
		}

		.inquiry-button {
			cursor: pointer;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			display: flex;
			align-items: center;
			text-align: center;
			color: var(--voucher-white-color);
			background-color: transparent;
			padding: 0;
			border: 0;
		}

		.loader-container {
			position: absolute;
			bottom: 0;
			height: 100%;
		}
	}

	&.mobile {
		height: 100%;

		.button-area {
			position: absolute;
			bottom: 0;
		}
	}
}
