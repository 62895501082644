.user-info-booking-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 24px 16px;

	& > p {
		margin: 0;
		font-weight: 700;
		font-size: 20px;
		line-height: 26px;
		color: var(--gray-900);
	}

	& > .logout-button {
		padding: 6px 14px;
		border: 1px solid rgba(17, 44, 75, 0.6);
		border-radius: 31px;
		background: white;
		font-weight: 400;
		font-size: 14px;
		line-height: 17px;
		letter-spacing: -0.01em;
		color: rgba(17, 44, 75, 0.6);
		cursor: pointer;
	}
}
