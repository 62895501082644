.voucher-card-wrapper {
	display: flex;
	align-items: center;
	border-radius: 5px;
	border: solid 0.5px #e3e5ec;
	background-color: #ffffff;
	box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.08);
	user-select: none;

	.voucher-card-image-area {
		width: 52.2px;
		height: 100%;
		padding: 14px 10px;
		margin-right: 12px;
		border-radius: 4px 0 0 4px;

		.voucher-card-image {
			width: 52.2px;
			object-fit: contain;
		}
	}

	.voucher-information {
		display: flex;
		flex-direction: column;
		align-items: flex-start;

		.voucher-name {
			font-size: 12px;
			font-weight: 500;
			line-height: 18px;
			font-stretch: normal;
			font-style: normal;
			letter-spacing: -0.3px;
			text-align: left;
			color: var(--gray-600);
			margin-bottom: 1px;
		}

		.voucher-price {
			display: flex;

			p {
				margin: 0;
			}

			.sale-price {
				flex-grow: 0;
				font-size: 16px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: -0.3px;
				text-align: left;
				line-height: 24px;
				color: var(--voucher-accent-color);
				margin-right: 7px;

				.percent {
					font-weight: 600;
				}
			}

			.amount {
				flex-grow: 0;
				font-size: 16px;
				line-height: 24px;
				font-weight: 500;
				font-stretch: normal;
				font-style: normal;
				letter-spacing: -0.3px;
				text-align: left;
				color: #000000;
			}
		}
	}
}
