.service-container {
	top: 0;
	position: fixed;
	z-index: 10;
	background: white;
	max-height: 100vh;
	overflow-y: scroll;
	text-align: left;
	padding: 0 16px;
	margin: 0 auto;
	max-width: var(--max-width);
	box-sizing: border-box;
	width: 100%;
	left: 0;
	right: 0;
	min-height: 100vh;
	.header {
		display: flex;
		justify-content: space-between;
		margin: 10px 8px;
	}

	.service-title {
		font-size: 15px;
		font-weight: 700;
		margin-bottom: 8px;
	}

	.service-body {
		font-size: 14px;
		line-height: 21px;
		word-break: break-all;
		text-align: left;
		margin-bottom: 10px;
	}
}

.close-button {
	svg {
		height: 14px;
		vertical-align: middle;
		margin: auto;
	}
}

.close-title {
	font-size: 1rem;
	font-weight: 700;
}
