.modal {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.6);
}
.modal-body {
	white-space: pre-line;
}
.modal.openModal {
	display: flex;
	align-items: center;
	justify-content: center;
}
