.booking-menu-list-container {
	margin: 2rem 1rem 0;

	.menu-list {
		border: 0.0625rem solid #dedfe0;
		border-radius: 0.25rem;
		font-size: 0.875rem;

		.menu-box {
			text-align: left;
			padding: 1rem 0;
			margin: 0 1rem;
			border-bottom: 0.0625rem solid #dedfe0;
			&:last-child {
				border-bottom: none;
			}

			.menu-name {
				word-break: keep-all;
				color: var(--gray-700);
			}

			.menu-price {
				color: var(--gray-900);
				font-weight: 500;
			}
		}
	}
}
