.reservation-item-container {
	width: 100%;
	padding: 1.25rem 0 0;
	display: flex;

	.mall-container {
		display: flex;

		.mall-image {
			border-radius: 0.25em;
			min-width: 64px;
			min-height: 64px;
			max-width: 104px;
			max-height: 104px;
			display: inline-block;
			position: relative;
			width: calc(100vw - 133px - 91px - 2rem);
			height: calc(100vw - 133px - 91px - 2rem);
			.dim {
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				color: white;
				display: flex;
				align-items: center;
				justify-content: center;
				text-align: center;
				font-size: 0.8125rem;
				background-color: rgba(0, 0, 0, 0.4);
				border-radius: 0.5rem;

				min-width: 64px;
				min-height: 64px;
				max-width: 104px;
				max-height: 104px;
			}
			.badge {
				position: absolute;
				bottom: 0;
				left: 0;
				display: flex;
				align-items: center;
				justify-content: center;
				width: 100%;
				text-align: center;
				background: var(--quaternary-color);
				color: var(--tertiary-text-color);
				border-radius: 0 0 0.25em 0.25em;
				padding: 0.1875rem 0;
			}
		}
	
		.mall-right-area {
			padding: 0 0 0 8px;
			display: inline-block;
			vertical-align: top;
			position: relative;
	
			.mall-name {
				font-size: 0.875rem;
				font-weight: 600;
				line-height: 1.5rem;
				color: var(--gray-800);
			}
	
			.mall-info {
				margin-bottom: 0.625rem;
				padding-right: 36px;
	
				&.date-info {
					color: var(--gray-600);
					margin: 0.125rem 0 0 0;
					padding-right: 0;
				}
	
				&.people-info {
					color: var(--gray-600);
					margin: 0.125rem 0 0.625rem;
					margin-top: 0.5rem;
				}
			}
		}
	}
}
