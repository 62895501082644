.recommend-item-container {
	margin: 0 1rem 0 0;
	padding-bottom: 1.25rem;

	&:first-child {
		padding-left: 1rem;
	}

	&:last-child {
		padding-right: 1rem;
	}

	.mall-name {
		margin-top: 0.125rem;
	}

	.mall-info {
		margin-top: 0.25rem;
		margin-bottom: 0.625rem;
		.mall-food {
			color: var(--gray-600);
		}
	}

	.recommend-item-image {
		height: 100px;
		width: 160px;
		border-radius: 0.25rem;
		margin-bottom: 0.375rem;
	}
}
