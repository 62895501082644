.deposit-notice-container.share-component {
	margin: 0;

	.notice-box {
		margin: 0 20px;

		.description {
			@include small_2;
			color: var(--gray-900);
			word-spacing: initial;
			
			.noshow {
				color: var(--red-200);
			}
		}
	}
}
