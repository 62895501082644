.voucher-item-container {
	width: 100%;
	padding: 1.25rem 0 0;
	display: flex;
	.mall-container {
		display: flex;
		width: 100%;
		.mall-image {
			border-radius: 0.25em;
			min-width: 64px;
			min-height: 64px;
			max-width: 104px;
			max-height: 104px;
			display: inline-block;
			position: relative;
			width: calc(100vw - 133px - 91px - 2rem);
			height: calc(100vw - 133px - 91px - 2rem);

			.dim {
				color: white;
				line-height: 6.5rem;
				text-align: center;
				font-size: 0.8125rem;
				background-color: rgba(0, 0, 0, 0.4);
				border-radius: 0.5rem;
			}
			.badge {
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				text-align: center;
				background: var(--quaternary-color);
				color: var(--tertiary-text-color);
				border-radius: 0 0 0.25em 0.25em;
				padding: 0.1875rem 0;
			}
		}

		.mall-right-area {
			padding: 0 0 0 8px;
			display: flex;
			justify-content: space-between;
			position: relative;
			width: 100%;
			height: 100%;
			// height: 6.5rem;

			.mall-content {
				min-width: 133px;
				flex: 1;

				.mall-name {
					max-height: 20px;
					font-weight: 600;
					margin-bottom: 6px;
					width: 100%;
					overflow-y: hidden;
				}

				.mall-info {
					color: var(--gray-600);
					max-height: 17px;
					width: 100%;
					overflow: hidden;

					&.voucher-info {
						max-height: 34px;
					}

					&.people-info {
						margin: 3px 0;
					}
				}
			}

			.mall-action {
				width: max-content;
				display: flex;
				flex-direction: column;
				align-self: flex-end;
				margin-left: 8px;
				max-width: 75px;
				
				.use-button {
					max-height: 31px;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 1px solid var(--gray-300);
					padding: 7px 14px;
					box-sizing: border-box;
					border-radius: 31px;
					white-space: nowrap;
					font-weight: 400;
					line-height: 17px;
					letter-spacing: -0.75px;
					color: var(--gray-900);

					&:first-of-type {
						color: var(--primary-color);
						margin-bottom: 8px;
					}
				}
			}
		}
	}
}
