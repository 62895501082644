.deposit-notice-container {
	margin: 0 20px 2rem;

	.deposit-container {
		.title {
			@include title_16_m2;
			display: flex;
			justify-content: space-between;
			align-items: center;
			word-spacing: initial;
			
			.money {
				color: var(--red-200);
			}
		}
	}
	
	.separator-line {
		background-color: var(--gray-100);
		width: 100%;
		margin: 1rem 0;
		height: 1px;
		box-sizing: border-box;
	}

	.noshow-container {
		ul {
			@include small_2;
			margin: 0 0 0 20px;
			padding: 0;
			color: var(--gray-500);
			
			.noshow-text {
				color: var(--red-200);
			}
		}
	}
	
	.total-deposit {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 16px;
		
		.text {
			@include body_14_m;
		}
		
		.value {
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.003em;
			color: var(--red-200);
		}
	}
}
