.detail-party-size-container {
	padding: 0 1rem 2rem;

	.people-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		border: 1px solid var(--gray-300);
		border-radius: 0.125rem;
		padding: 0.75rem 1rem;
		.label-container {
			.description {
				display: flex;
				margin-top: 0.25rem;
				align-items: center;
				.icon {
					width: 0.75rem;
					height: 0.75rem;
					margin-right: 0.25rem;
				}
			}
		}
		.select-container {
			.select-people-box {
				height: 2.5rem;
				border: none;
				border-radius: 0.125rem;
				-webkit-appearance: none;
				outline: 0;
				min-width: 70px;
				padding-left: 0.75rem;
				background: url(/assets/icon/chevronDown.svg) 90% 50% no-repeat;
				background-size: 1.25rem 1.25rem;
				color: var(--gray-900);
			}
		}
	}
}
