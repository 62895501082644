.user-info-container {
	display: flex;
	flex-direction: column;
	padding: 0 20px;
	margin-bottom: 40px;

	.info {
		&-container {
			@include body_12_m;
			margin: 8px 0 0 14px;
			padding: 0;
		}

		&-list {
			margin-bottom: 10px;
			color: var(--gray-600);

			&:last-of-type {
				margin-bottom: 0;
			}
		}

		&-box {
			display: flex;
			align-items: center;

			.label {
				width: 66px;
			}

			.value {
				@include body_12;
				color: var(--gray-900);
			}
		}
	}
}
