.notice-container {
	margin: 0 1rem 1.5rem;
	.notice-title {
		margin-bottom: 1.5rem;
	}
	.notice {
		white-space: pre-line;
		color: var(--gray-800);
	}
	.separator-line {
		background-color: var(--gray-200);
		width: 100%;
		margin: 1.5rem 0;
		height: 1px;
		box-sizing: border-box;
	}
}
