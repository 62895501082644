.chatbot-info-container {
	display: flex;
	color: #6f6f6f;
	font-size: 14px;
	padding: 0 16px;
	.notice-icon {
		line-height: 14px;
	}

	.chatbot-text {
		margin-left: 4px;
		line-height: 16px;
	}
}
