.main-container {
	.carousel-wrapper {
		user-select: none;

		.carousel-container {
			width: 100%;
			display: flex;
			align-items: flex-start;
			overflow-y: scroll;

			.slider {
				width: 360px;
				margin-left: 16px;
				padding-left: 16px;

				.slide {
					min-width: 360px !important;
					margin-left: -20px;
					background: transparent;

					&.selected {
						margin: 0;
					}
				}
			}
		}

		.carousel {
			.control-arrow {
				display: none !important;
			}
		}
	}

	.main-banner-wrapper {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		overflow-y: scroll;
		min-height: 287px;
		height: auto;
		margin-top: 16px;
		margin-bottom: 60px;
		user-select: none;

		.carousel-container {
			.slider {
				padding-left: 0;
				margin-left: 16px;

				.slide {
					margin-left: -29px;

					&.selected {
						margin-left: 7px;
					}
				}
			}
		}

		.main-banner-image {
			// min-width: 315px;
			// width: 100%;
			width: calc(100% - 32px);
			margin: 0 16px;
			// height: 287px;
			border-radius: 5px;
			box-shadow: 0 10px 20px 0 rgba(43, 50, 69, 0.1);

			&:after {
				content: '';
				background-image: linear-gradient(to top, #000000, rgba(17, 44, 75, 0)),
					linear-gradient(
						to top,
						#000000,
						rgba(6, 6, 6, 0.79),
						rgba(0, 0, 0, 0)
					),
					linear-gradient(
						to top,
						#d5daee,
						rgba(213, 218, 238, 0.88),
						rgba(213, 218, 238, 0)
					),
					linear-gradient(to top, rgba(0, 0, 0, 0.54), rgba(255, 255, 255, 0)),
					linear-gradient(to bottom, #f1f4fc, #f1f4fc);
			}
		}
	}

	.voucher-products,
	.wish-it-voucher-wrapper,
	.missed-lives-wrapper,
	.inquiry-banner-wrapper,
	.customer-inquiry-wrapper {
		margin-bottom: 72px;
	}

	.voucher-products {
		padding: 0 16px;
	}

	.wish-it-voucher-wrapper,
	.missed-lives-wrapper {
		.title {
			padding-left: 16px;
		}

		.wish-it-products,
		.missed-lives {
			display: flex;
			align-items: flex-start;
			overflow-y: scroll;
			padding: 0 16px;
		}
	}

	.real-review-wrapper {
		margin-bottom: 25px;

		.title {
			padding-left: 16px;
		}

		.real-review-images {
			height: 300px;
			display: flex;
			align-items: flex-start;
			overflow-y: scroll;
			padding: 0 16px;
			.real-review-image-wrapper {
				margin-right: 16px;

				.real-review-image {
					width: 264px;
					height: 253px;
					background: var(--voucher-white-color);
					box-shadow: 0px 12px 42px -4px rgba(24, 39, 75, 0.12);
					border-radius: 5px;
					&:last-child {
						margin-right: 0;
					}
				}
			}
		}
	}

	.inquiry-banner-wrapper {
		display: flex;
		align-items: flex-start;
		overflow-y: scroll;

		.banner {
			min-width: 328px;
			height: 99px;
			cursor: pointer;
		}

		.partnership-inquiry-banner {
			background: url(/assets/icon/banner/franchiseInquiry.png);
			background-size: cover;
			width: 328px;
		}

		.kakao-channel-banner {
			background: url(/assets/icon/banner/kakaoChannel.png);
			background-size: cover;
			width: 328px;
		}

		.kakao-ad-banner {
			width: 328px;
			height: 99px;
			display: block;
			background-color: #f7f7f7;
		}
	}

	.customer-inquiry-wrapper {
		padding: 0 16px;
		height: 97px;

		.customer-inquiry-banner {
			max-width: 328px;
			width: 100%;
			cursor: pointer;
			box-shadow: 0px 8px 18px -6px rgba(24, 39, 75, 0.12);

			&.mobile {
				max-width: unset;
			}
		}
	}
}
