@import './../../../Components/Voucher/BottomSheet/BottomSheet.scss';

.voucher-detail-container {
	margin-bottom: 51px;
	position: relative;

	.voucher-detail-wrapper {
		.detail-image-area {
			display: flex;

			.detail-image {
				width: 100%;
			}
		}

		.video-area {
			height: 191px;
			top: 50px;
			&.none-header {
				top: 0;
			}
			.video-iframe {
				width: 100%;
				height: 100%;
			}
		}
	}

	.recommend-voucher-box {
		position: sticky;
		bottom: 141px;
		justify-content: center;
		margin-right: 6px;
		width: fit-content;
		overflow: hidden;
		float: right;
		border-radius: 25px;
		align-items: center;
		display: none;
		padding: 4px;
		cursor: pointer;
		&.show {
			display: flex;
			animation: zoomIn 0.8s;
			background: #ffffff;
			box-shadow: 0 2px 10px rgba(0, 0, 0, 0.25);
		}
		&.show {
			.icon-box {
				border: none;
			}
		}
		.icon-box {
			background: #558fff;
			box-shadow: 0 4px 10px rgba(0, 0, 0, 0.05);
			border: 4px solid white;
			border-radius: 50%;
			height: 28px;
			width: 28px;
			display: flex;
			justify-content: center;
			align-items: center;
		}
	}
	.recommend-text {
		display: flex;
		align-items: center;
		font-weight: bold;
		font-size: 12px;
		line-height: 24px;
		margin-left: 8px;
		&.show {
			animation: expand 1s;
		}
		.icon {
			width: 7px;
			height: 7px;
			margin-left: 6px;
			margin-right: 14px;
		}
		.recommend-aonther-text {
			animation: lateFadeIn 1.2s;
			margin-right: 14px;
		}
	}
}

@keyframes zoomIn {
	from {
		opacity: 0;
		transform: scale3d(0.3, 0.3, 0.3);
		background: transparent;
		box-shadow: none;
	}
	40% {
		transform: scale3d(1, 1, 1);
		background: transparent;
		box-shadow: none;
		opacity: 1;
	}
	100% {
		box-shadow: none;
		background: transparent;
	}
}

@keyframes expand {
	from {
		width: 0;
		height: 0;
		overflow: hidden;
		margin: 0;
		background: transparent;
		box-shadow: none;
	}

	60% {
		width: 0;
		background: transparent;
		box-shadow: none;
	}
	to {
		width: 114px;
		background: transparent;
		box-shadow: none;
		height: 0;
		overflow: hidden;
		margin: 0;
	}
}

@keyframes lateFadeIn {
	from {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

.not-found-container {
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	margin-top: 64px;
	padding: 0 16px;
	box-sizing: border-box;
	.description {
		text-align: center;
		margin-bottom: 32px;
	}
	.antoher-voucher-button {
		width: 100%;
		min-width: 328px;
		height: 44px;
		border-radius: 3px;
		border: solid 1px #e3e5ec;
		background-color: #ffffff;
	}
}
