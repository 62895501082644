.my-reservation-info-container {
	padding: 0px 1rem 2rem;

	.mall-info-title {
		font-size: 20px;
		font-weight: 700;
		margin: 0 0 50px;
	}

	.mall-name {
		font-size: 1.25rem;
		font-weight: 500;
		margin-bottom: 1.25rem;
		color: var(--black-200);
		line-height: 1.625rem;
		word-break: keep-all;
		margin-top: 1.5rem;
	}

	.reservation-info {
		margin-bottom: 0.375rem;
		text-align: left;
		color: black;
		font-size: 0.875rem;
		display: flex;

		& > div {
			display: inline-block;
		}

		.label {
			width: 70px;
			line-height: 1.1875rem;
			color: var(--gray-600);
		}

		.value {
			line-height: 1.1875rem;
		}
	}
	.state-info-container {
		padding-top: 40px;
		
		.state-info {
			padding-bottom: 4px;
			font-weight: 500;
			font-size: 16px;
			line-height: 21px;

			&.reserv-cancel {
				color: var(--functional-color-negative);
			}
			
			&.finish, &.cancel {
				color: var(--gray-500);
			}
		}
	}
	.noshow-box {
		padding: 0.75rem 1.6875rem;
		margin: 2rem 0 2rem;
		background: #f5f5f5;
		font-size: 0.75rem;
		line-height: 1.1875rem;
		border-radius: 0.25rem;

		.noshow-text {
			color: #ff360a;
		}
	}
}
