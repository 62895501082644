.checkbox-container {
	display: flex;
	flex-direction: column;

	.checkbox-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
		.checkbox-label {
			font-size: 16px;
			line-height: 21px;
			color: #242629;
		}
		.check-icon {
			width: 24px;
			height: 24px;
		}
		.option-price {
			color: #242629;
			font-size: 16px;
			font-weight: 500;
			line-height: 21px;
			letter-spacing: -0.16px;
		}
	}
}
