details[open] summary ~ * {
	animation: sweep 0.5s ease-in-out;
}

@keyframes sweep {
	0% {
		opacity: 0;
		transform: translateX(-10px);
	}
	100% {
		opacity: 1;
		transform: translateX(0);
	}
}

details summary {
	list-style: inside none;
	&:focus {
		outline: none;
	}
}
details summary::-webkit-details-marker {
	display: none;
}

.collapse {
	--sp-sm: 1.8rem;
	--sp-md: 1.8rem;

	width: 100%;
	margin: 0 auto;

	background-color: #f5f4f9;
	transition: box-shadow 250ms;
	will-change: box-shadow;
	overflow: hidden;
	display: flex;

	.collapse-title {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: var(--sp-md);
		user-select: none;
		cursor: pointer;
		background-color: white;

		.question-title {
			display: flex;
			justify-content: space-between;
			width: 100%;
			align-items: center;
			.cursor {
				display: flex;
				width: 20px;
				height: 20px;
				transform: rotate(180deg);
			}
			.question {
				display: flex;
				margin: 0px;
				padding-right: 5px;
				max-width: 90%;
				font-weight: bold;
				line-height: 23px;
			}
		}
	}

	&[open] .question-title > .cursor {
		display: flex;
		align-items: center;
		width: 20px;
		height: 20px;
		transform: rotate(0deg);
	}

	.description {
		padding: var(--sp-md);
		margin: 0;
		font-size: 13px;

		&:not(:first-of-type) {
			padding-top: 0;
		}
	}
}
