.page-entire-modal {
	position: fixed;
	width: 100%;
	max-width: 360px;
	height: 100vh;
	top: 0;
	left: auto;
	right: auto;
	background-color: var(--voucher-white-color);
	z-index: 1000;

	.modal-container {
		height: 100vh;
		position: relative;
		padding: 0 16px;

		.modal-header {
			display: flex;
			align-items: center;
			padding: 10px 0;
			height: 26px;
			user-select: none;

			.close-icon {
				width: 26px;
				height: 26px;
				cursor: pointer;
			}

			.header-title {
				font-style: normal;
				font-weight: 500;
				font-size: 16px;
				line-height: 26px;
				text-align: center;
				color: var(--black-000);
				margin: 0;
				width: 100%;
			}
		}

		.modal-body {
			padding: 0;
			margin: 25px 0;

			.contents-title {
				font-style: normal;
				font-weight: 600;
				font-size: 20px;
				line-height: 28px;
				color: var(--gray-900);
				margin-bottom: 52px;
			}

			.contents-description {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 20px;
				color: var(--black-000);
				margin: 0;
			}
		}

		.modal-footer {
			display: flex;
			align-items: center;
			justify-content: center;
			background: rgba(17, 44, 75, 0.2);
			position: fixed;
			width: 100%;
			max-width: calc(360px - 32px);
			bottom: 32px;

			.button {
				width: 100%;
				height: 44px;
				padding: 0;
				border: 1px solid #112c4b;
				box-sizing: border-box;
				border-radius: 3px;
				background-color: var(--voucher-primary-color);
				color: var(--voucher-white-color);

				.text {
					font-style: normal;
					font-weight: normal;
					font-size: 16px;
					line-height: 20px;
					text-align: center;
					color: #ffffff;
				}
			}
		}
	}

	&.mobile {
		max-width: unset;

		.modal-footer {
			max-width: calc(100% - 32px);
		}
	}
}
