.voucher-notice-container {
	padding: 0 1rem;
	.separator-line {
		background-color: var(--gray-200);
		width: 100%;
		margin: 1.5rem 0;
		height: 1px;
		box-sizing: border-box;
	}
	.booking-subtitle-container {
		margin-bottom: 2rem;
	}
	.subheader {
		margin-bottom: 1.25rem;
	}
	.notice-line {
		display: flex;
		margin-bottom: 1.125rem;
		a {
			color: inherit;
		}
		&.warning {
			color: var(--voucher-accent-color);
		}
	}
}
