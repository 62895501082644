.gyeongjuro-menu-description-container {
	padding: 20px 16px 24px;
	border-bottom: 1px solid #E9EBEE;
	.menu-name {
		font-size: 20px;
		line-height: 25px;
		color: #242629;
		margin-bottom: 14px;
		font-weight: bold;
	}

	.menu-description {
		font-size: 14px;
		line-height: 18px;
		max-height: 30px;
		overflow: hidden;
		color: #6C717A;
	}
}
