.deposit-notice-container {
	padding: 0 1rem 2rem;
	.noshow-container {
		.noshow-box {
			padding: 1rem 0.75rem;
			margin: 2rem 0 2rem;
			background: var(--gray-100);
			color: var(--gray-700);
			display: flex;
			.noshow-text {
				color: var(--functional-color-negative);
			}
			.icon {
				min-width: 13px;
				height: 13px;
				vertical-align: middle;
			}
			.notice {
				margin-left: 0.75rem;
			}
		}
	}

	.deposit-container {
		.title {
			display: flex;
			justify-content: space-between;
			.money {
				color: var(--functional-color-negative);
			}
		}
	}
	.separator-line {
		background-color: var(--gray-200);
		width: 100%;
		margin: 1.5rem 0;
		height: 1px;
		box-sizing: border-box;
	}
}
