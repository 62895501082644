.user-info-form-container {
	padding: 0 16px;

	.label {
		font-size: 14px;
		line-height: 17px;
		margin-bottom: 4px;

		&.required {
			&:after {
				content: ' *';
				color: #ff2222;
			}
		}
	}

	.input {
		outline: none;
		border: 1px solid #c1c1c1;
		border-radius: 4px;
		width: 100%;
		font-size: 14px;
		line-height: 17px;
		padding: 12px;
		box-sizing: border-box;
		margin-bottom: 18px;

		&:last-child {
			margin-bottom: 0;
		}
	}

	.input-button {
		outline: none;
		border: 1px solid #0075ff;
		border-radius: 4px;
		width: 100%;
		font-size: 14px;
		line-height: 17px;
		padding: 12px;
		box-sizing: border-box;
		margin-bottom: 18px;
		cursor: pointer;
		background-color: #0075ff;
		color: white;
		font-size: 0.7em;
		float: right;
	}

	.input-group {
		display: flex;
		> .input {
			width: 100%;
			border-radius: 4px 0 0 4px;
			border-right: none;
		}

		> .input-button {
			min-width: 88px;
			width: 88px;
			white-space: nowrap;
			border-radius: 0 4px 4px 0;
			border-left: none;
		}
	}
}
