.facility-item-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 50px;
	width: 25%;

	.facility-image {
		display: flex;
		justify-content: center;
		align-items: center;

		img {
			width: 27px;
			height: 27px;
			margin-bottom: 0.625rem;
		}
	}
}
