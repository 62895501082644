.gyeongjuro-shortcut-container {
	position: sticky;
	top: 48px;
	display: flex;
	overflow: scroll;
	margin-bottom: 32px;
	border-bottom: 1px solid #E0E4EB;
	background-color: white;
	a {
		text-decoration: none;
		color: black;
	}
	.shortcut {
		color: #858B93;
		font-size: 15px;
		font-weight: 400;
		line-height: 20px; /* 133.333% */
		letter-spacing: -0.3px;
		padding: 14px 5px;
		gap: 16px;
		min-width: max-content;
		&:first-child {
			margin-left: 16px;
		}
		&.selected{
			color: #52A1FF;
			font-weight: 700;
			border-bottom: 2px solid #52A1FF;
		}
	}
	&::-webkit-scrollbar {
		display: none;
	}
}
