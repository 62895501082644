.mall-pickup-description-container {
	padding: 24px 16px 0;
	.subtitle {
		flex-direction: row;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: -0.36px;
		margin-bottom: 20px;
	}
	.row {
		display: flex;
		.label {
			min-width: 50px;
			font-size: 14px;
			line-height: 18px;
			margin-right: 12px;
			color: #858B93;
			margin-bottom: 6px;
		}
		.value {
			font-size: 14px;
			line-height: 18px;
			color: var(--gray-800);
		}
	}
}
