.gyeongjuro-menu-item-container {
	padding: 18px 16px;
	display: flex;
	align-items: flex-start;
	justify-content: flex-start;
	border-bottom: 1px solid #E9EBEE;
	gap: 16px;
	.description-container {
		padding: 8px 0;
		.menu-name {
			font-size: 16px;
			line-height: 21px;
			font-weight: bold;
			margin-bottom: 4px;
		}
		.menu-description {
			color: #6C717A;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.14px;
			max-height: 36px;
			overflow: hidden;
		}
		.menu-price {
			font-size: 14px;
			line-height: 18px;
			font-weight: 600;
			margin-top: 6px;
			color: #242629;

		}
	}
	.image-container {
		min-height: 104px;
		min-width: 104px;
		img {
			border-radius: 4px;
			width: 104px;
			height: 104px;
		}
	}
}
