.autocomplete-item-container {
	text-align: left;
	padding: 0 1rem;
	height: 46px;
	display: flex;
	align-items: center;
	&.searched {
		background: var(--gray-000);
	}

	.search-icon-container {
		display: flex;
		align-items: center;

		.search-value {
			display: flex;
			align-items: center;
			.highlight {
				color: var(--primary-color);
			}
		}

		.search-icon {
			width: 1.25rem;
			height: 1.25rem;
			margin-right: 0.5rem;
		}
	}
}
