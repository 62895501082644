.booking-user-info-form-container {
	padding: 0 1rem 2rem;
	.input-label {
		color: var(--gray-900);
		margin-bottom: 0.25rem;
		.firebrick {
			color: var(--functional-color-negative);
		}
	}
	.input-box {
		margin-bottom: 1.25rem;
		position: relative;
		&.email {
			margin-bottom: 0.625rem;
		}
		.input {
			outline: none;
			border: 0.0625rem solid #c1c1c1;
			border-radius: 0.25rem;
			width: 100%;
			padding: 0.75rem;
			box-sizing: border-box;
			&.error {
				border: 1px solid var(--functional-color-negative);
			}
			&:focus {
				border: 1px solid var(--gray-900);
				& + .clear-button {
					opacity: 1;
				}
			}
			&:focus-within {
				border: 1px solid var(--gray-900);
			}
			&.auth-number {
				width: 65%;
			}

			&:placeholder-shown {
				& + .clear-button {
					display: none !important;
				}
			}
		}
		.phone-change-button {
			position: absolute;
			right: 0.875rem;
			top: 0.375rem;
			border: 1px solid var(--primary-color);
			border-radius: 1.5rem;
			color: black;
			padding: 0.375rem 0.875rem;
		}
	}
	.phone-auth-box {
		display: flex;
		justify-content: space-between;
		align-items: center;

		.phone-box {
			display: flex;
			border: 0.0625rem solid #c1c1c1;
			border-radius: 0.25rem;
			box-sizing: border-box;
			width: 65%;
			&.disabled {
				background: rgba(239, 239, 239, 0.3);
			}
			.phone-number {
				text-align: center;
				font-size: 0.875rem;
				line-height: 17px;
				padding: 0 0.875rem;
				margin: 0.75rem 0;
				outline: none;
				border: none;
				width: 25%;
				border-right: 0.0625rem solid #c1c1c1;

				&:last-child {
					border-right: none;
				}
			}
		}
		.auth-button {
			box-shadow: none;
			line-height: 2rem;
			padding: 5px 6px;
			flex: 1;
			margin-left: 0.75rem;
		}
	}
}

input:disabled {
	background: rgba(239, 239, 239, 0.3);
}

.clear-button {
	width: 2.1875rem;
	height: 2.75rem;
	position: absolute;
	right: 10px;
	top: 0;
	opacity: 0;
	transition-property: all;
	transition-duration: 1s;
	transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	.icon {
		width: 1.25rem;
		height: 1.25rem;
	}
}
