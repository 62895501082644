.billing-form-container {
	.tab-list {
		display: flex;
		justify-content: center;
		margin-bottom: 16px;

		.tab-button {
			width: 50%;
			text-align: center;
			padding: 14px 0;
			font-size: 14px;
			line-height: 17px;
			border: 1px solid #e6e6e6;

			&:last-child {
				border-radius: 0 4px 4px 0;
			}

			&:first-child {
				border-radius: 4px 0 0 4px;
			}

			&.selected {
				background: var(--primary-color);
				border: 1px solid var(--primary-color);
				color: #ffffff;
			}
		}
	}

	.payment-form {
		border: 1px solid #e6e6e6;

		.card-box {
			display: flex;
			border-bottom: 1px solid #e6e6e6;

			.card-number {
				text-align: center;
				font-size: 14px;
				line-height: 17px;
				padding: 0 27px;
				margin: 18px 0;
				outline: none;
				border: none;
				width: 25%;
				border-right: 1px solid #e6e6e6;
			}

			.text-security {
				-webkit-text-security: disc;
			}
		}

		.expire-box {
			width: 100%;
			display: flex;
			height: 54px;
			border-bottom: 1px solid #e6e6e6;
			.select-form {
				width: 100%;
			}
			.month-select-box {
				width: 50%;
				box-sizing: border-box;
				padding: 0 32px;
				.month-select {
					width: 100%;
				}
			}
			.year-select-box {
				width: 50%;
				box-sizing: border-box;
				padding: 0 32px;
				.year-select {
					width: 100%;
				}
			}
		}
		.input-box {
			border-bottom: 1px solid #e6e6e6;
			input {
				font-size: 14px;
				line-height: 17px;
				padding: 0 27px;
				margin: 18px 0;
				outline: none;
				border: none;
				width: 100%;
				box-sizing: border-box;
			}
			&:last-child {
				border-bottom: none;
			}
		}
	}
}
