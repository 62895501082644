.gyeongju-date-time-picker-container {
	padding: 0 16px;

	.select-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		.select-box {
			padding: 14px 14px 14px 16px;
			border: 1px solid #d6d8d8;
			border-radius: 4px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			svg {
				width: 20px;
				height: 20px;
			}
		}
	}
	.relative-target {
		position: relative;
		width: 100%;
		&.first {
			margin-right: 8px;
		}
	}
	.date-picker-box {
		width: 0;
		position: absolute;
		top: -16px;
	}

}
