.party-size-selector-container {
	margin: 1rem 1rem;
	display: flex;
	justify-content: space-between;
	align-items: center;

	&:not(.isLoaded) {
		display: none;
	}

	.icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		.people-value {
			margin: 0 1.125rem;
			display: flex;
			align-items: center;
			font-weight: 400;
		}

		.icon {
			svg {
				width: 2.25rem;
				height: 2.25rem;
				vertical-align: middle;
			}
		}
	}
}
