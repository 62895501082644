.request-form-container {
	padding: 0 1rem 4rem;

	.request-textarea {
		border-radius: 0.125rem;
		transition: border-color 0.2s;
		padding-right: 0.625rem;
		background: white;
		width: 100%;
		font-size: 0.875rem;
		box-sizing: border-box;
		padding-left: 0.625rem;
		padding-top: 0.625rem;
		border: 0.0625rem solid var(--gray-400);

		&::placeholder {
			color: var(--gray-400);
		}

		&:focus {
			border: 0.0625rem solid var(--gray-900);
			outline: none;
		}

		&:focus-within {
			border: 0.0625rem solid var(--gray-900);
			outline: none;
		}
	}

	.description {
		display: flex;
		padding: 0.5rem 0;
		font-size: 0.75rem;

		.notice {
			margin-left: 0.5rem;
		}
		.icon {
			min-width: 13px;
			height: 13px;
		}
	}
}
