.recommend-container {
	.subtitle-container {
		margin: 2rem 1rem 0;
	}

	.slider-container {
		display: flex;
		overflow: scroll;
		-ms-overflow-style: none;
		&::-webkit-scrollbar {
			background-color: transparent;
			width: 0;
			height: 0;
			background: transparent;
			display: none;
		}
	}
}
