.category-selector-container {
	padding: 0 1rem;
	font-size: 0.875rem;

	.title {
		margin-bottom: 0.875rem;
	}
	.radio-box {
		.radio-label {
			.MuiTypography-body1 {
				font-size: 0.875rem;
			}
		}
	}
}
