.month-handler {
	width: 100%;
	height: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 24px;
	box-sizing: border-box;
	user-select: none;

	.date-text {
		font: {
			weight: 500;
			size: 16px;
		}

		word-spacing: initial;
		line-height: initial;
	}

	.action-container {
		display: flex;
		align-items: center;

		& > svg {
			cursor: not-allowed;

			&:first-of-type {
				margin-right: 20px;
			}

			&.enable {
				cursor: pointer;
			}

			&:not(.enable) path {
				stroke: #d6d8db;
			}
		}
	}
}
