.MyModal {
	background: rgba(0, 0, 0, 0.25);
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	min-height: 100vh;
	width: 100vw;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: center;

	.ModalContainer {
		height: 100vh;
		position: relative;
		.close-button {
			position: absolute;
			top: 1rem;
			right: 0;
			transform: translate(-50%, -50%);
			height: 14px;
			vertical-align: middle;
			margin: auto;
		}
	}

	.content {
		background-color: white;
	}

	#iframe-mobile {
		// max-width: 360px;
		background: white;
		width: 100vw;
		height: 100vh;
	}
	#iiframe-desktop {
		max-width: 100vw;
	}
}
