.payment-menu-list-container {
	.payment-menu-header-container {
		padding: 0 16px;
		display: flex;
		justify-content: space-between;
		.my-menu-subtitle {
			display: flex;
			gap: 6px;
			font-size: 18px;
			font-weight: 600;
			line-height: 23px;
			letter-spacing: -0.36px;
			margin-bottom: 20px;
			.menu-count-badge {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 1px 8px;
				font-size: 14px;
				line-height: 18px;
				background: var(--gray-2, #E9EBEE);
				border-radius: 12px;
			}
		}
		.expand-button {
			width: 20px;
			height: 20px;
			transition: all .5s ease-in-out;
			&.reverse {
				transform: rotate(180deg);
			}
		}
	}
	.pickup-menu-container {
		height: 0;
		overflow: hidden;
		transition: all .5s ease-in-out;
		padding: 0 16px;
		&.open {
			overflow: visible;
			height: max-content;
		}
		.pickup-menu-box {
			padding: 20px 0;
			border-bottom: 1px solid #F1F2F3;
			.row {
				display: flex;
				justify-content: space-between;
				align-items: center;
				margin-bottom: 8px;
			}
			.menu-option-group-container {
				display: flex;
				flex-direction: column;
				margin-bottom: 8px;
			}
			.menu-name {
				font-size: 16px;
				font-weight: 600;
				line-height: 21px;
			}
			.menu-count {
				&::before {
					content: '';
					display: inline-block;
					height: 12px;
					width: 1px;
					background: #DEE1E5;
					margin: 0 6px;
				}
			}
			.menu-price {
				color: #242629;
				font-size: 16px;
				font-weight: 600;
				line-height: 20px;
				letter-spacing: -0.5px;
			}
		}
	}
	.total-payment-menu-contianer {
		padding: 20px 16px 8px;
		.row {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 16px;
			.label {
				font-size: 14px;
				font-weight: 600;
				line-height: 18px;
				letter-spacing: -0.14px;
			}
			.value {
				font-size: 14px;
				font-weight: 500;
				line-height: 18px;
				letter-spacing: -0.14px;
				&.total-price {
					font-size: 18px;
					line-height: 21px;
					font-weight: 600;
				}
			}
		}
	}
}
