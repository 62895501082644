.gyeongjuro-radiobox-group {
	display: flex;
	flex-direction: column;
	gap: 32px;
	.gyengjuro-radio-label {
		font-size: 16px;
		line-height: 21px;
		margin-left: 14px;
	}
}
