.menu-info-container {
	display: flex;
	justify-content: space-between;
	padding: 16px;
	.menu-name {
		font-size: 15px;
		line-height: 19px;
		color: var(--gray-800);
	}

	.menu-price {
		font-size: 15px;
		line-height: 19px;
		color: var(--gray-800);
	}
}
