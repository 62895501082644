.gyeongjuro-menu-option-container {
	padding: 16px;
	border-top: 8px solid #f1f2f3;
	.menu-option-name {
		font-size: 18px;
		line-height: 23px;
		font-weight: bold;
		margin-bottom: 16px;
		.required {
			color: #ff2222;
		}
	}
}
