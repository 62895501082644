.title-container {
	margin: 24px 16px;
	.title {
		font-size: 24px;
		line-height: 25px;
		font-weight: bold;
		margin-bottom: 4px;
	}
	.description {
		font-size: 14px;
		color: #6f6f6f;
	}
}
