.region-slider-container {
	display: flex;
	overflow: scroll;
	position: sticky;
	top: 0;
	background: white;
	z-index: 5;
	border-bottom: 0.0625rem solid var(--gray-300);
	padding: 1rem 0 0;
	-ms-overflow-style: none;
	.region {
		text-align: center;
		margin-right: 1.125rem;
		padding: 0 0.125rem 0.875rem;
		flex: 0 0 auto;
		&:first-child {
			margin-left: 1rem;
		}

		&:last-child {
			margin-right: 1rem;
		}

		&.selected {
			border-bottom: 0.1875rem solid var(--primary-color);
			color: var(--primary-color);
			font-weight: 700;
		}
	}
}
