.timer-container {
	* {
		color: var(--red-200) !important;
	}

	p {
		margin: 0;
	}

	& > div {
		display: flex;
		align-items: center;
		background-color: var(--quaternary-color-alpha-05);

		&.timer-box {
			justify-content: space-between;
			margin: 0 20px 40px;
			padding: 14px 32px;
			border-radius: 4px;

			.time {
				font-weight: 500;
				font-size: 16px;
				letter-spacing: 0.003em;
			}
			
			.text {
				@include small_2;
				word-spacing: initial;
			}
		}

		&.timer-mini {
			width: 100%;
			max-width: var(--max-width);
			justify-content: center;
			position: fixed;
			top: 48px;
			z-index: 10;

			&.none-header {
				top: 0;
			}
			
			span {
				@include small_2;
				margin: 7px 0;
			}
			
			.timer-mini-remain-box {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				
				.time {
					font-weight: 500;
					font-size: 12px;
					line-height: 13px;
				}
			}
		}
	}
}
