.button {
	cursor: pointer;
	user-select: none;

	.button-icon {
		width: 20px;
		height: 20px;
		margin: 0 8px 0 0;
		object-fit: contain;
	}

	&.active {
		border: solid 1px var(--voucher-primary-color);
		background-color: var(--voucher-primary-color);
	}
}
