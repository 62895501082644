.gnb-drawer-container {
	height: 100vh;
	background-color: #ffffff;
	position: relative;
	user-select: none;
	margin: 16px;

	.gnb-controller {
		display: block;
		height: 24px;

		.close-button {
			cursor: pointer;
			position: absolute;
			top: 0;
			right: 0;
		}
	}

	.user-wrapper {
		margin-top: 24px;

		.user-viewer {
			font-style: normal;
			font-weight: bold;
			font-size: 18px;
			line-height: 22px;
			border-bottom: 0.5px solid #e5eaef;
			margin: 0;
			padding-bottom: 12px;
			margin-bottom: 24px;
			color: var(--black-200);
		}

		.login-wrapper {
			padding-bottom: 24px;
			margin-bottom: 24px;
			border-bottom: 0.5px solid #e5eaef;

			.description {
				font-style: normal;
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				color: var(--black-000);
				margin-bottom: 16px;
			}

			.login-button {
				cursor: pointer;
				padding: 8px 10px;
				background-color: var(--voucher-primary-color);
				border: 1px solid #112c4b;
				box-sizing: border-box;
				border-radius: 5px;
				color: var(--voucher-white-color);
			}
		}
	}

	.item-wrapper {
		width: auto !important;
		height: auto !important;
		padding-bottom: 24px;
		margin-bottom: 24px;
		border-bottom: 0.5px solid #e5eaef;

		&:last-child {
			border: 0;
		}

		.item-list {
			width: auto !important;
			height: auto !important;
			cursor: pointer;
			font-style: normal;
			font-weight: normal;
			font-size: 14px;
			line-height: 17px;
			margin-bottom: 24px;
			color: var(--black-200);

			&.selected {
				font-weight: bold;
			}

			&:last-child {
				margin: 0;
			}
		}
	}
}
