.mall-description-container {
	padding: 2rem 1rem 6.5rem;
	font-size: 0.875rem;
	line-height: 1.375rem;
	color: #464647;
	font-weight: 500;
	.break-line {
		white-space: pre-wrap;
	}
}
