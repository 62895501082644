.order-sheet-footer-container {
	padding: 22px 16px 0;
	border-top: 1px solid #e6e6e6;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: var(--max-width);
	box-sizing: border-box;
	background: white;
	.info-container {
		display: flex;
		justify-content: space-between;
		margin-bottom: 16px;
		.label {
			font-size: 16px;
			color: var(--gray-800);
			line-height: 24px;
		}
		.value {
			font-size: 24px;
			line-height: 30px;
			color: var(--gray-800);
			font-weight: 800;
		}
	}

	.action-container {
		display: flex;
		justify-content: space-between;
		padding: 16px 0;
		div {
			width: 50%;
			text-align: center;
			background-color: #f0f5fb;
			height: 52px;
			line-height: 52px;
			border-radius: 8px;
			&:first-child {
				margin-right: 16px;
			}
		}
	}
}

.order-sheet-footer-container-placeholder {
	height: 154px;
	width: 100%;
}
