.gyeongjuro-menu-category-container {
	margin-bottom: 60px;
	.category-name {
		padding: 0 16px 20px;
		color: #242629;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: -0.36px;
	}
}
