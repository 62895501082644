.header-container {
	width: 100vw;
	max-width: var(--max-width);

	height: 48px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	position: fixed;
	top: 0;
	z-index: 10;
	padding: 0 18px 0 15px;
	box-sizing: border-box;
	background-color: white;
	user-select: none;

	& > svg {
		cursor: pointer;
	}

	& > h1 {
		margin: 0;

		font: {
			weight: 500;
			size: 16px;
		}

		letter-spacing: -0.3px;
	}
}

.header-container-placeholder {
	width: 100vw;
	max-width: var(--max-width);
	height: 48px;
}
