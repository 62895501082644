.login-drawer-container {
	width: 360px;
	height: 380px;
	background-color: #ffffff;
	position: relative;
	user-select: none;

	&.mobile {
		width: 100%;
	}

	.close-button {
		width: 24px;
		height: 24px;
		object-fit: contain;
		position: absolute;
		top: 24px;
		right: 24px;
		cursor: pointer;
	}

	.visit-welcome-title-first,
	.visit-welcome-title-second {
		font-size: 28px;
		font-weight: bold;
		text-rendering: optimizeLegibility;
		line-height: 1.25;
		letter-spacing: normal;
		text-align: center;
		color: #000000;
	}

	.visit-welcome-title-first {
		width: 134px;
		margin: 72px auto 0px;
	}

	.visit-welcome-title-second {
		margin: 0px 49px 12px 72px;
		white-space: nowrap;

		.emoji {
			font-weight: normal;
		}
	}

	.visit-welcome-sub-title {
		height: 66px;
		margin: 0 auto 32px;
		font-size: 14px;
		font-weight: normal;
		line-height: 1.57;
		letter-spacing: -0.3px;
		text-align: center;
		color: #76777f;
	}

	.social-login-button-container {
		display: flex;
		justify-content: center;
		margin: 0 93px 70px 93px;
	}
}
