.gyeongjuro-menu-action-container {
	display: flex;
	.icon-container {
		display: flex;
		align-items: center;

		.icon {
			width: 34px;
			height: 34px;
				
			svg {
				width: 20px;
				height: 20px;
				padding: 6px;
				border: 1px solid #CFD3D9;
				&.minus {
					border-radius: 2px 0 0 2px;	
				}
				&.plus {
					border-radius: 0 2px 2px 0;	
				}
			}
		}

		.count {
			height: 32px;
			width: 42px;
			color: var(--gray-800);
			font-size: 14px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid #CFD3D9;
			border-bottom: 1px solid #CFD3D9;
		}
	}
}
