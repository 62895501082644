.date-time-picker-container {
	padding: 0 16px;

	.select-container {
		display: flex;
		align-items: center;
		justify-content: space-evenly;

		.label {
			font-size: 14px;
			line-height: 17px;
			margin-right: 22px;
		}

		.select-box {
			padding: 10px;
			border: 1px solid #c1c1c1;
			width: fit-content;
			border-radius: 4px;

			svg {
				width: 16px;
				height: 8px;
				margin-left: 10px;
			}
		}
	}
}

.relative-target {
	position: relative;
	&.first {
		margin-right: 8px;
	}
}

.date-picker-box {
	width: 0;
	position: absolute;
	top: -16px;
}
