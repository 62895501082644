.reservation-list-container {
	padding: 0 1rem 2rem;
	.title {
		margin: 1.25rem 0 0;
		display: flex;
		justify-content: space-between;
		.expand {
			color: var(--primary-color);
			display: flex;
			align-items: center;
			.icon {
				width: 18px;
				height: 18px;

				> path {
					stroke: var(--primary-color) !important;
				}
			}
		}
	}
}
