.reservation-preview-container {
	padding: 0 1rem;
	margin-top: 2.0625rem;
	margin-bottom: 3rem;
	
	.info-box {
		margin-bottom: 0.375rem;
		text-align: left;
		display: flex;

		& > div {
			display: inline-block;
		}

		.label {
			width: 70px;
			color: var(--gray-600);
		}
	}
}
