.booking-info-view-container {
	display: flex;
	flex-direction: column;
	margin: 0 1rem 2.5rem;
	padding: 1.875rem 0 0;

	.mall-name {
		margin-bottom: 1.25rem;
	}

	&.mini {
		margin-bottom: 2rem;

		.mall-name {
			margin-bottom: 0.125rem;
		}
	}

	.detail-info {
		&-box {
			display: flex;
			justify-content: space-evenly;
			align-items: center;
			padding: 0.875rem 0;
			border: 0.0625rem solid var(--gray-300);
			border-radius: 0.25rem;
			box-shadow: 0 2px 12px -2px rgba(24, 39, 75, 0.1);

			& > div {
				width: 100%;
				display: flex;
				flex-direction: column;
				text-align: center;

				label {
					color: var(--gray-600);
					margin-bottom: 1rem;
				}

				span {
					color: var(--gray-800);
				}
			}
		}

		&-text {
			font-size: 0.875rem;
			color: var(--gray-600);
		}
	}
}
