.my-reservation-info-container {
	padding: 0 20px 40px;

	.state-info-container {
		padding-top: 24px;

		.state-info {
			padding-bottom: 4px;
			font-weight: 500;
			font-size: 12px;
			line-height: 14px;
			letter-spacing: -0.3px;
			
			&.reserv-cancel {
				color: var(--red-200);
			}

			&.finish, &.cancel {
				color: var(--gray-500);
			}
		}
	}

	.container.border {
		display: flex;
		flex-direction: column;

		.mall-info-title {
			@include title_18;
			margin: 0 0 40px;
		}

		.info {
			&-container {
				@include body_12_m;
				margin: 8px 0 0 14px;
				padding: 0;
			}

			&-list {
				margin-bottom: 10px;
				color: var(--gray-600);

				&:last-of-type {
					margin-bottom: 0;
				}
			}

			&-box {
				display: flex;
				align-items: center;

				.label {
					width: 66px;
				}

				.value {
					@include body_12;
					color: var(--gray-900);
				}
			}
		}
		
		.user-info-space {
			height: 40px;
		}
		
		.noshow-info {
			@include small_2;
			margin-top: 12px;
			color: var(--gray-500);
			
			.noshow-text {
				color: var(--red-200);
			}
		}
	}
}
