.mall-item-container {
	width: 100%;
	padding: 1.25rem 0 0 0;
	display: flex;
	.mall-image {
		border-radius: 8px;
		width: 6.5rem;
		min-width: 6.5rem;
		height: 6.5rem;
		display: inline-block;

		.mall-discount {
			border-radius: 4px 0 4px 0;
			background: var(--quaternary-color);
			color: var(--tertiary-text-color);
			width: 2.25rem;
			height: 21px;
			box-sizing: border-box;
			display: flex;
			align-items: center;
			justify-content: center;
		}
	}

	.mall-right-area {
		padding: 0 0 0 8px;
		display: inline-block;
		vertical-align: top;
		position: relative;

		&.long {
			padding-bottom: 18px;

			.mall-name {
				margin-top: -17px;
			}
		}

		.mall-name {
			margin-bottom: 0.125rem;
			font-weight: 600;
		}

		.mall-info {
			margin-bottom: 0.25rem;
			padding-right: 36px;
			&.date-info {
				color: var(--gray-600);
				margin: 0.125rem 0 0 0;
				line-height: 1.25rem;
				padding-right: 0;
				letter-spacing: -0.01em;
			}

			&.people-info {
				margin: 0.125rem 0 10px;
				margin-top: 0.5rem;
			}
		}

		.mall-description {
			color: var(--gray-700);
			text-overflow: ellipsis;
			overflow: hidden;
			-webkit-line-clamp: 2;
			height: 30px;
			display: -webkit-box;
			-webkit-box-orient: vertical;
		}

		.detail-container {
			margin-top: 10px;

			.detail-box {
				width: 140px;
				height: 1.25rem;
				border: 1px solid var(--primary-color);
				border-radius: 4px;
				font-size: 0.75rem;
				line-height: 19px;
				text-align: center;
				color: var(--primary-color);
				font-weight: 400;
				margin-right: 10px;
				display: inline-block;
			}
		}
	}
}
