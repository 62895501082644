.footer-container {
	border-top: 1px solid #e0e0e0;
	display: block;

	div {
		text-align: center;
		vertical-align: middle;
		line-height: 16px;
	}

	.logo-container {
		cursor: pointer;
		margin-top: 0.8rem;
		font-size: 0.8rem;
		padding: 0 0.8rem;
		color: #8a8a8a;
		display: flex;
		justify-content: center;
		align-items: center;

		.logo {
			height: 12px;
			margin-right: 8px;
		}
	}

	.title-container {
		display: flex;
		justify-content: center;
		align-items: baseline;
		margin: 8px 0;

		.arrow {
			height: 7px;
			vertical-align: middle;
			cursor: pointer;
		}

		.footer_title {
			font-size: 0.8rem;
			padding: 0 0.8rem;
			color: #8a8a8a;
		}
	}

	.info-container {
		margin-bottom: 8px;
	}

	.terms-container {
		font-size: 0.8rem;
		margin-bottom: 1.3rem;
		color: #8a8a8a;
		cursor: pointer;
		> span {
			padding: 0.2rem 0.3rem;
			&:first-child {
				border-right: 1px solid #8a8a8a;
				line-height: 8px;
			}
		}
	}
}
