.modal {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background-color: rgba(0, 0, 0, 0.6);
	.message-container {
		text-align: left;
		font-size: 1rem;
		line-height: 1.25rem;
		.title {
			color: #313234;
			font-size: 1rem;
			font-weight: 700;
			margin-bottom: 0.75rem;
		}
		.desc {
			color: var(--gray-800);
			font-size: 0.875rem;
			margin-bottom: 2rem;
		}
		.bold {
			font-weight: 600;
			text-align: left;
			margin-top: 30px;
			margin-bottom: 0.625rem;
			margin-left: 5px;
		}
		.refund-money-text {
			color: var(--gray-900);
			font-size: 0.8125rem;
		}
		.money {
			color: var(--functional-color-negative);
			font-size: 0.875rem;
		}

		div.inline {
			text-align: left;
			margin-bottom: 0.625rem;
			padding: 0 5px;
			&.emphasis {
				font-weight: bold;
			}
			.item-name {
				color: var(--gray-600);
				font-size: 0.8125rem;
			}
			.item-value {
				color: var(--gray-800);
				font-size: 0.875rem;
			}
			div {
				display: inline-block;
				vertical-align: top;
			}

			div.right-text {
				float: right;
			}
		}

		div.bottom-line {
			width: 100%;
			height: 0.0625rem;
			background-color: var(--gray-800);
			margin-top: 0.625rem;
			margin-bottom: 0.625rem;
		}
	}
}
.modal.openModal {
	display: flex;
	align-items: center;
	justify-content: center;
}
