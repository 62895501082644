.voucher-product-item {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	margin-bottom: 40px;
	padding: 0;

	&.clickable {
		cursor: pointer;
	}

	.vocuher-information {
		width: 100%;
		position: relative;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		margin-top: 16px;

		.voucher-category {
			height: 12px;
			font-size: 10px;
			font-weight: 500;
			text-align: left;
			color: var(--gray-500);
		}

		.mall-name {
			font-size: 18px;
			font-weight: bold;
			text-align: left;
			color: var(--gray-900);
			margin: 6px 0 6px;
		}

		.voucher-product-description {
			font-size: 14px;
			font-weight: normal;
			text-align: left;
			color: var(--gray-800);
			margin-top: 0;
			margin-bottom: 16px;
		}

		.wish-it-wrapper {
			position: absolute;
			top: 0;
			right: 0;
			display: flex;
			flex-direction: column;
			align-items: center;
			user-select: none;

			.like-icon {
				cursor: pointer;
				width: 32px;
				height: 32px;
			}

			.like-count-text {
				margin: 0;
				flex-grow: 0;
				font-size: 12px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				color: var(--gray-500);

				&.my-wish {
					color: var(--voucher-accent-color);
				}
			}
		}

		.voucher-price-info {
			margin-bottom: 16px;

			.sale-product-alert-text {
				font-size: 12px;
				font-weight: normal;
				text-align: left;
				color: var(--voucher-accent-color);
				margin: 0;
				margin-bottom: 6px;

				.stroke-amount {
					color: var(--gray-500);
				}
			}

			.voucher-price {
				font-size: 18px;
				font-weight: bold;
				font-stretch: normal;
				font-style: normal;
				line-height: normal;
				letter-spacing: normal;
				text-align: left;
				margin: 0;

				.sale-price {
					color: var(--voucher-accent-color);
					margin-right: 6px;
				}
			}
		}

		.voucher-alert-count-wrapper {
			width: 117px;
			height: 14px;
			font-size: 12px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: var(--voucher-accent-color);
			margin-bottom: 16px;

			.count {
				font-weight: bold;
			}
		}
	}
}
