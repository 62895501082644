.gallery-image {
	width: 50vw;
	height: 50vw;
	overflow: hidden;
	display: inline-block;
	background-size: cover;
	background-position: 50% 50%;
	margin: 0;
	padding: 0;
}
