.agree-checkbox {
	display: flex;
	align-items: center;
	.expand {
		color: #242424;
		font-weight: 600;
	}
}

.checkbox-label {
	font-size: 14px;
	line-height: 20px;
	color: #8b8b8b;
}

.agreement-arrow {
	width: 18px;
	height: 18px;
}