.theyeyak-with-brands-wrapper {
	margin: 0 0 72px;
	padding: 40px 0;
	background-color: #f5f7f8;

	.theyeyak-with-brands-title {
		height: 25px;
		margin: 0 0 16px 16px;
		font-size: 21px;
		font-weight: 600;
		font-stretch: normal;
		font-style: normal;
		line-height: normal;
		letter-spacing: normal;
		text-align: left;
		color: var(--gray-800);
	}

	.theyeyak-brands-list {
		display: flex;
		overflow: hidden;
		overflow-x: scroll;
		padding: 0 16px;
	}
}
