.option-group-container {
	padding: 2px 16px;

	.option-desc {
		font-size: 13px;
		line-height: 16px;
		color: var(--gray-800);
		opacity: 0.5;
	}
}
