.category-selector-container {
	display: flex;
	flex-direction: column;
	margin: 0 20px;
	
	.gyeongju-radio-box {
		width: fit-content;
		display: flex;
		flex-direction: column;

		.gyeongju-radio-component:not(:last-of-type) {
			margin-bottom: 20px;
		}
	}
}
