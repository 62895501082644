.agreement-container {
	padding: 0 16px 64px;
	.icon {
		width: 24px;
		height: 24px;
	}
	.agreement-seperator {
		height: 1px;
		background-color: #DEE1E5;
		width: 100%;
		margin: 20px 0;
	}
	.checkbox-label {
		margin-right: 0;
	}
}
