.product-info-container {
	padding: 40px 1rem 0;
    margin-bottom: 32px;
	.info-box {
		margin-bottom: 0.375rem;
		text-align: left;
		display: flex;

		&.purchase-amount-wrap {
			.value {
				font-weight: 600;
			}
		}

		& > div {
			display: inline-block;


			span {
				display: block;
			}
		}

		.label {
			min-width: 70px;
			color: var(--gray-600);
		}
	}
}
