.searched-item-container {
	width: 100%;
	padding: 1.25rem 0 0 0;
	display: flex;
	.mall-image {
		border-radius: 8px;
		width: 6.5rem;
		min-width: 6.5rem;
		height: 75px;
		display: inline-block;
	}

	.mall-right-area {
		padding: 0 0 0 8px;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: center;
		.mall-name {
			margin-bottom: 0.25rem;
			.highlight {
				color: var(--primary-color);
			}
		}
	}

	.mall-food {
		color: var(--gray-600);
	}
}
