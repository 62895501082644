.voucher-email-anchor {
  text-decoration: none;

  .email-inquiry-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 48px;
    padding: 0;
    margin-top: 12px;
    border: 0;
    border-radius: 5px;
    box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
    background-color: #ffffff;
    cursor: pointer;

    .email-icon {
      width: 18px;
      height: 12.5px;
      background: url(/assets/icon/emoji_mail.png);
      background-size: cover;
    }

    .text {
      height: 17px;
      flex-grow: 0;
      margin: 0 0 0 8px;
      font-size: 14px;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: left;
      color: var(--voucher-primary-color);
    }
  }
}
