.pickup-info-container {
	padding: 24px 16px 0;
	.status-text {
		font-weight: 600;
		font-size: 14px;
		line-height: 17px;
		color: #47a000;
		margin-bottom: 4px;
	}
	.mall-name {
		font-weight: bold;
		font-size: 20px;
		line-height: 25px;
		margin-bottom: 20px;
		svg {
			display: none;
		}
	}

	.row {
		display: flex;
		margin-bottom: 6px;
		.label {
			min-width: 60px;
			font-size: 14px;
			line-height: 18px;
			margin-right: 20px;
			color: #858B93;
		}

		.value {
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: #242629;
		}
	}
}
