.booking-subtitle-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin-bottom: 1.25rem;
	.left {
		display: flex;
		.subtitle {
			margin-bottom: 0;
		}
	}
	.count {
		background-color: var(--gray-200);
		padding: 0.125rem 0.5rem;
		border-radius: 0.875rem;
		font-size: 0.875rem;
		font-weight: 500;
		margin-left: 0.375rem;
	}
	.move {
		color: var(--primary-color);
		display: flex;
		align-items: center;
		.icon {
			width: 18px;
			height: 18px;
		}
	}
}
