.address-container {
	padding: 0 1rem;
	.address-box {
		position: relative;
		background: #fafafa;
		padding: 0.75rem 1rem;
		margin: 0.625rem 0 0.5rem;

		.street-address {
			display: flex;

			.marker-icon {
				width: 1.125rem;
				height: 1.25rem;
				margin-right: 0.75rem;
			}
			.text {
				display: block;
				line-height: 1.375rem;
				font-size: 0.875rem;
			}
		}

		.domicile-address {
			display: flex;
			position: relative;
			margin-top: 0.5rem;
			.domicile-icon {
				margin-right: 0.75rem;
				width: 2.25rem;
				height: 1.25rem;
			}
			.text {
				display: block;
				line-height: 1.375rem;
				font-size: 0.875rem;
				color: var(--gray-600);
			}
		}
	}
	.address-phone-container {
		display: flex;
		margin-top: 0.875rem;
		.icon-container {
			.call-icon {
				width: 1.125rem;
				height: 1.125rem;
			}
		}
		.phone {
			cursor: pointer;
			.tel {
				color: var(--accent-color);
				text-decoration: none;
			}
		}
	}
}
