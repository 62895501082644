.reservation-item-wrapper {
	padding-bottom: 24px;
	border-bottom: 2px solid var(--voucher-border-gray);
	padding: 24px 16px;
	cursor: pointer;

	&:last-child {
		border-bottom: 0;
	}

	.reservation-information-wrapper {
		display: flex;
		align-items: center;
		position: relative;

		.reservation-thumbnail-wrapper {
			position: relative;
			width: 64px;
			height: 64px;
			margin-right: 10px;

			.thumbnail {
				width: 64px;
				height: 64px;
				border-radius: 60px;

				&.cancel {
					-webkit-filter: brightness(60%);
					filter: brightness(60%);
				}
			}

			.cancel-mark {
				position: absolute;
				top: 50%;
				left: 50%;
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				color: #fff;
				font-size: 16px;
				font-weight: 600;
			}
		}

		.reservation-information {
			.reservation-name {
				font-weight: bold;
				font-size: 16px;
				line-height: 19px;
				color: #0c0c0c;
				margin-bottom: 6px;
			}

			.reservation-date,
			.reservation-people-count {
				font-size: 12px;
				line-height: 14px;
				color: #919191;
				margin: 0;
				margin-bottom: 6px;
			}
		}

		.arrow-button {
			position: absolute;
			right: 0;

			.right-arrow {
				width: 10.12px;
				height: 18.36px;
			}
		}
	}
}
