table.refund-policy-table {
	width: 100%;
	margin-top: 0.875rem;
	border-top: 0.0625rem solid var(--gray-900);
	thead {
		background: #fcfcfc;

		th {
			height: 2rem;
			border-bottom: 0.0625rem solid var(--gray-200);
			width: 50%;
			font-weight: normal;
			color: var(--gray-800);
			&:first-child {
				border-right: 0.0625rem solid var(--gray-200);
			}
		}
	}

	tbody {
		td {
			height: 2rem;
			text-align: center;
			line-height: 2rem;
			border-bottom: 0.0625rem solid var(--gray-200);
			color: var(--gray-800);
			&:first-child {
				border-right: 0.0625rem solid var(--gray-200);
			}
		}
	}
}
