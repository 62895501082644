.brand-introduce-view {
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-right: 14px;
	min-width: 85px;

	&:last-child {
		margin-right: 0;
	}

	.brand-logo-image {
		width: 83px;
		height: 83px;
		margin: 0;
		margin-bottom: 16px;
		box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
		border-radius: 60px;
	}

	.brand-name {
		font-size: 14px;
		font-weight: 600;
		text-align: center;
		word-break: keep-all;
		color: var(--black-200);
	}
}
