.booking-info-footer-container {
	background-color: var(--gray-100);
	padding: 0 1rem 3rem;
	display: flex;
	flex-direction: column;
	.footer-shinhan-sub {
		padding-top: 1.875rem;
		font-size: 12px;
		color: var(--gray-600);

		& > b {
			font-weight: normal;
			color: var(--primary-color);
		}
	}
	.footer-description {
		margin-top: 1.875rem;
		margin-bottom: 1.5rem;
		font-size: 12px;
		color: var(--gray-600);
		line-height: 18px;
	}
	.footer-button-container {
		display: flex;
		align-items: center;
		margin-bottom: 1.5rem;
		.policy-wrapper {
			display: flex;
			align-items: center;
		}
		.footer-button {
			color: var(--gray-700);
			margin: 0 0.5rem;
			font-size: 12px;
			&:first-child {
				margin-left: 0;
			}
		}
		.icon {
			width: 1px;
			height: 11px;
		}
	}

	.footer-sub {
		font-size: 11px;
		color: var(--gray-600);
	}
}
