.kt-user-container {
	.title {
		font-size: 17px;
		margin-top: 20px;
	}
	.reservation-info-container {
		margin: 0 1.25rem;
		padding: 0 0 1.5rem;
		border-bottom: 1px solid #b2b2b2;
		.row {
			display: flex;
			font-size: 15px;
			color: #b2b2b2;
			margin-bottom: 10px;
			line-height: 20px;
			.label {
				width: 56px;
				margin-right: 12px;
			}

			.value {
			}
		}
	}
}
