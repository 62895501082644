.modal {
	display: none;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 99;
	background: rgba(36, 38, 41, 0.4);

	.message-container {
		text-align: left;
		font-size: 1rem;
		line-height: 1.25rem;

		.desc {
			font-weight: 400;
			font-size: 14px;
			line-height: 20px;
			text-align: center;
			letter-spacing: -0.03em;
			word-break: break-all;
		}

		.bold {
			margin: 16px 0;
			font-weight: 500;
			font-size: 14px;
			line-height: 16px;
			letter-spacing: -0.03em;
		}

		.refund-info {
			margin: 0 0 0 14px;
			padding: 0;
			font-weight: 500;
			font-size: 12px;
			line-height: 12px;
			color: var(--gray-600);

			& > li {
				&:first-of-type {
					margin-bottom: 10px;
				}

				& > div {
					display: flex;
					justify-content: space-between;
					align-items: center;

					& > span {
						font-weight: 400;
						font-size: 13px;
						line-height: 13px;
						color: var(--gray-900);
					}
				}
			}
		}

		div.inline {
			text-align: left;
			margin-bottom: 0.625rem;
			padding: 0 5px;
			
			&.emphasis {
				display: flex;
				justify-content: space-between;
				align-items: center;
				padding: 0;
				margin: 0;

				.refund-money-text {
					@include body_12_m;
					color: var(--gray-900);
				}

				.money {
					font-weight: 500;
					font-size: 14px;
					line-height: 16px;
					letter-spacing: -0.003em;
					color: var(--red-200);
				}
			}

			div {
				display: inline-block;
				vertical-align: top;
			}

			div.right-text {
				float: right;
			}
		}

		div.bottom-line {
			width: 100%;
			height: 1px;
			background-color: var(--gray-900);
			margin: 12px 0;
		}
	}
}

.modal.openModal {
	display: flex;
	align-items: center;
	justify-content: center;
}
