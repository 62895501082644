.voucher-malls-container {
	.slider-container {
		display: flex;
		overflow: scroll;
		&::-webkit-scrollbar {
			background-color: transparent;
			width: 0;
			height: 0;
			background: transparent;
			display: none;
		}
	}
}
