@keyframes openToast {
	from {
		transform: translateY(10px);
	}

	to {
		transform: translateY(0);
	}
}

.gyeongju-toast-container {
	width: 100vw;
	max-width: var(--max-width);

	display: flex;
	justify-content: center;
	position: inherit;
	bottom: 68px;
	padding: 0 20px;
	box-sizing: border-box;
	user-select: none;
	animation: openToast 200ms ease-out;

	&.close {
		transform: translateY(10px);
		opacity: 0;
		transition: all 200ms ease-out;
	}

	& > label {
		padding: 12px 14px;
		background-color: #52a1ff;
		opacity: 0.93;
		box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
		border-radius: 8px;
		color: white;
		font-weight: 500;
		font-size: 13px;
		line-height: 14px;
		letter-spacing: -0.3px;
	}
}
