.calendar-container {
	display: flex;
	flex-direction: column;
	margin: 0 20px;

	&:not(.isLoaded) {
		display: none;
	}

	.picker-container {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		
		div {
			width: calc(100% + 26px);
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			user-select: none;
			color: var(--gray-900);

			&.week-list {
				font: {
					weight: 400;
					size: 14px;
				}

				* {
					margin-top: 12px;
					border: 1px solid #fff;
					opacity: unset;
					cursor: pointer;

					&.selected {
						background-color: rgba(82, 161, 255, 0.2);
						border: 1px solid #52a1ff;
					}

					&.deactive {
						color: #bbbec3;
						cursor: not-allowed;
					}

					&.hide {
						opacity: 0;
						cursor: unset;
					}
				}
			}

			div {
				width: calc((100% - 54px) / 7); // item margin 값 * 6 = 54px
				height: calc((100vw - 68px) / 7);
				max-height: calc(
					(var(--max-width) - 68px) / 7
				); // 54 + 상위 컴포넌트 margin 값 양쪽 각 20px - container width 값 26px / 2 = 7 * 2 = 68px

				display: flex;
				justify-content: center;
				align-items: center;
				border-radius: 4px;
				box-sizing: border-box;

				&:nth-of-type(7n + 1) {
					color: var(--red-200);
				}

				&:nth-of-type(7n + 7) {
					color: var(--blue);
				}

				&:not(:nth-of-type(7n + 1)) {
					margin-left: 9px;
				}
			}
		}

		.no-item-noti {
			height: 308px;
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 40px 0 0;
			text-align: center;
			word-break: keep-all;
			font-weight: 400;
			font-size: 16px;
			letter-spacing: -0.01em;
		}
	}
}
