.booking-user-info-form-container {
	margin: 0 20px 40px;

	.input-label {
		@include title_16_m;
		width: fit-content;
		display: flex;
		flex-direction: row-reverse;
		gap: 4px;
		margin-bottom: 14px;
		color: var(--gray-900);
		word-spacing: initial;

		.firebrick {
			color: var(--blue);
		}
	}

	.input-box {
		position: relative;
		margin-bottom: 32px;

		&.email {
			margin-bottom: 12px;

			& + .form-description {
				margin-bottom: 40px;
			}
		}

		.input {
			@include small_2;
			width: 100%;
			padding: 18px 16px 15px;
			border: 0;
			border-bottom: 1px solid var(--gray-300);
			box-sizing: border-box;
			-webkit-appearance: none;
			-webkit-border-radius: 0;
			word-spacing: initial;
			color: var(--gray-900);
			transition: border-bottom 200ms;

			&:focus {
				outline: 0;
				border-bottom: 1px solid #52a1ff;

				& + .clear-button {
					opacity: 1;
				}
			}

			&:-webkit-autofill {
				-webkit-box-shadow: 0 0 0 1000px white inset;
			}

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&:disabled {
				background: unset;
				cursor: not-allowed;
			}

			&::placeholder {
				color: var(--gray-500);
			}

			&:placeholder-shown {
				& + .clear-button {
					display: none !important;
				}
			}
		}
	}

	.auth-button {
		position: absolute;
		top: 0;
		right: 0;
		padding: 8px 16px;
		border: 1px solid #52a1ff;
		border-radius: 4px;
		background-color: white;
		cursor: pointer;
		font-weight: 400;
		font-size: 12px;
		line-height: 14px;
		letter-spacing: -0.03em;
		color: #52a1ff;

		&:disabled {
			cursor: not-allowed;
		}
	}

	.clear-button {
		width: 2.1875rem;
		height: 2.75rem;
		position: absolute;
		right: 10px;
		top: 0;
		opacity: 0;
		transition-property: all;
		transition-duration: 200ms;
		transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
		display: flex;
		justify-content: center;
		align-items: center;

		.icon {
			width: 1.25rem;
			height: 1.25rem;
		}
	}

	.form-description {
		@include small_2;
		padding: 0 16px;
		word-spacing: initial;
		color: #52a1ff;
	}
}
