.voucher-complete-container {
	position: relative;
	min-height: calc(100% - 50px);

	.mypage-button {
		background: var(--primary-color);
		width: calc(100% - 2rem);
		padding: 1rem 0;
		margin: 0 1rem;
		color: white;
		position: absolute;
		bottom: 2rem;
		box-shadow: none;
	}
}
