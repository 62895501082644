.menu-selector-container {
	padding: 0 1rem 2rem;

	.menu-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 1rem;
		border: 1px solid var(--gray-300);
		border-radius: 0.125rem;
		padding: 0.75rem 1rem;
		.label-container {
			.name {
				margin-bottom: 0.375rem;
			}
			.price {
				margin-top: 0.125rem;
				font-weight: 600;
				color: var(--gray-900);
			}
		}
		.select-container {
			.select-menu-box {
				color: #3b3b3d;
				height: 2.5rem;
				border: none;
				border-radius: 0.125rem;
				font-weight: 500;
				-webkit-appearance: none;
				outline: 0;
				width: 70px;
				padding-left: 0.75rem;
				background: url(/assets/icon/chevronDown.svg) 90% 50% no-repeat;
				background-size: 1.25rem 1.25rem;
			}
		}
	}
}
