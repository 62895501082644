.menu-action-container {
	display: flex;
	justify-content: space-between;
	padding: 0 16px;
	margin-top: 16px;

	.icon-container {
		display: flex;
		align-items: center;

		.icon {
			svg {
				width: 28px;
				height: 28px;
			}
		}

		.count {
			font-size: 16px;
			line-height: 20px;
			color: var(--gray-800);
			margin: 0 22px;
		}
	}
	.delete-button {
		border-radius: 4px;
		border: 1px solid #e6e6e6;
		color: var(--gray-800);
		font-size: 14px;
		padding: 6px 12px;
	}
}
