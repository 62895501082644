.outline-input-container {
	margin-bottom: 24px;

	.MuiFormControl-root {
		width: 100%;
	}

	.MuiFormLabel-asterisk {
		line-height: 23px;
		color: var(--voucher-accent-color);
	}

	.MuiOutlinedInput-root {
		border-radius: 4px;
		border: solid 1px var(--gray-300);
		background-color: var(--voucher-white-color);
		font-size: 16px;
		font-weight: normal;
		font-stretch: normal;
		font-style: normal;
		line-height: 1.5;
		letter-spacing: normal;
		text-align: left;
		color: var(--voucher-primary-color);

		fieldset {
			border: 0;
		}

		::placeholder {
			font-size: 14px;
			font-weight: normal;
			font-stretch: normal;
			font-style: normal;
			line-height: 1.5;
			letter-spacing: normal;
			text-align: left;
			color: #a5a9b2;
		}

		.MuiInputBase-input {
			.MuiOutlinedInput-input {
				height: 48px;
				padding: 13px 16px;
				border-radius: 2px;
				border: solid 1px var(--gray-300);
				background-color: #ffffff;
			}
		}

		.MuiOutlinedInput-input {
			padding: 13px 16px;
		}

		&.MuiOutlinedInput-multiline {
			padding: 0;

			textarea {
				overflow: auto !important;
				height: 158px !important;
			}
		}
	}

	.default {
		.Mui-focused {
			border: solid 1px var(--voucher-primary-color);
		}
	}

	.success {
		.Mui-focused {
			border: solid 1px var(--functional-color-positive);
		}
	}

	.fail {
		.Mui-focused {
			border: solid 1px var(--functional-color-negative);
		}
	}

	.label {
		height: 24px;
		flex-grow: 0;
		font-size: 16px;
		font-weight: 500;
		font-stretch: normal;
		font-style: normal;
		line-height: 23px;
		letter-spacing: normal;
		text-align: left;
		color: var(--gray-900);
		margin-bottom: 8px;
	}

	.required {
		font-size: 16px;
		color: var(--black-000);
	}
}
