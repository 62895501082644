@font-face {
	font-family: 'Noto Sans';
	src: url(../font/noto-sans/NotoSansKR-Thin.otf) format('otf');
	font-weight: 100;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/noto-sans/NotoSansKR-Light.otf) format('otf');
	font-weight: 300;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/noto-sans/NotoSansKR-Regular.otf) format('otf');
	font-weight: 400;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/noto-sans/NotoSansKR-Medium.otf) format('otf');
	font-weight: 500;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/noto-sans/NotoSansKR-Bold.otf) format('otf');
	font-weight: 700;
}

// Only numeric
@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-100.woff2) format('woff2');
	font-weight: 100;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-200.woff2) format('woff2');
	font-weight: 200;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-300.woff2) format('woff2');
	font-weight: 300;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-regular.woff2) format('woff2');
	font-weight: 400;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-500.woff2) format('woff2');
	font-weight: 500;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-600.woff2) format('woff2');
	font-weight: 600;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-700.woff2) format('woff2');
	font-weight: 700;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-800.woff2) format('woff2');
	font-weight: 800;
	unicode-range: U+0030-0039, U+002A;
}

@font-face {
	font-family: 'Noto Sans';
	src: url(../font/poppins/poppins-v20-latin-900.woff2) format('woff2');
	font-weight: 900;
	unicode-range: U+0030-0039, U+002A;
}
