.change-form-container {
	.title {
		font-size: 17px;
		padding: 0 1.25rem;
	}
	.input-box {
		padding: 0 1.25rem;
		.input-form {
			outline: 0;
			border: 0;
			padding-left: 0;
			border-bottom: 1px solid;
			width: 100%;
			font-size: 15px;
			line-height: 24px;
			&::placeholder {
				color: #b2b2b2;
			}
		}
	}

	.notice-list {
		margin-top: 10px;
		margin-bottom: 60px;
		padding: 0 1.25rem;
		.notice {
			font-size: 11px;
			line-height: 15px;
			color: #b2b2b2;
		}
	}
	.footer-container {
		border-top: 0;
		position: fixed;
		bottom: 16px;
		width: 100%;
		max-width: var(--max-width);
		display: flex;
		justify-content: center;
		.change-button {
			width: calc(100% - 32px);
			box-sizing: border-box;
			line-height: 40px;
			&.MuiButton-contained.Mui-disabled {
				background-color: #dddddd;
			}
		}
	}
}
