.menu-description-container {
	padding: 24px 16px;
	border-bottom: 1px solid #f1f1f1;
	.menu-name {
		font-size: 16px;
		line-height: 20px;
		color: var(--gray-800);
		margin-bottom: 10px;
		font-weight: bold;
	}

	.menu-description {
		font-size: 12px;
		line-height: 15px;
		color: #6f6f6f;
	}
}
