.find-restaurant-container {
	width: 100%;
	background: white;
	z-index: 5;
	margin: 2rem 0;

	.find-restaurant-button {
		height: 40px;
		border-radius: 0.25rem;
		margin: 0 1rem;
		background-color: white;
		padding-left: 0.875rem;
		text-align: left;
		position: relative;
		border: 0.0625rem solid var(--gray-400);
		display: flex;
		align-items: center;
		color: var(--gray-400);
		svg {
			width: 1.25rem;
			height: 1.25rem;
			position: absolute;
			right: 1.125rem;
			top: 0.5rem;
		}

		&:active {
			box-shadow: none;
			border-color: #2b2e38;
		}
	}
}
