.voucher-list-container {
	.voucher-list-content {
		padding: 0 1rem 2rem;

		.ticket-notes-wrapper {
			margin-left: -1rem;
		}

		.title {
			margin: 0;
			padding: 1.5rem 0 0;
			display: flex;
			justify-content: space-between;

			.expand {
				color: var(--primary-color);
				display: flex;
				align-items: center;

				.icon {
					width: 18px;
					height: 18px;

					> path {
						stroke: var(--primary-color) !important;
					}
				}
			}
		}
	}
}
