.user-info-container {
	padding: 0 1rem;
	.title {
		font-size: 1.25rem;
		font-weight: 500;
		margin-bottom: 1.25rem;
		color: var(--black-200);
		line-height: 1.625rem;
		word-break: keep-all;
		margin-top: 1.5rem;
	}
	.reservation-info {
		margin-bottom: 0.375rem;
		text-align: left;
		color: black;
		font-size: 0.875rem;
		display: flex;

		& > div {
			display: inline-block;
		}

		.label {
			width: 70px;
			line-height: 1.1875rem;
			color: var(--gray-600);
			&.request {
				line-height: 1.1875rem;
			}
		}
		.label {
			font-size: 0.875rem;
			&.request {
				word-break: break-all;
				line-height: 1.1875rem;
			}
		}
	}
}
