.user-info-container {
	padding: 0 16px;
	.row {
		display: flex;
		margin-bottom: 6px;
		.label {
			min-width: 60px;
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: #6f6f6f;
		}

		.value {
			font-size: 14px;
			line-height: 20px;
			margin-right: 12px;
			color: var(--gray-800);
		}
	}
}
