.seperator-container {
	height: 9px;
	width: 100%;
	background-color: #f5f5f5;
	margin: 32px 0;
}

.seperator-container-inline {
	width: 90%;
	margin: 24px auto;
	border-top: 0.1px solid gray;
}
