.customer-inquiry-container {
	.customer-inquiry-wrapper {
		padding: 14px 16px 0;

		.customer-inquiry-description {
			margin-bottom: 48px;

			.customer-inquiry-description-title {
				font-style: normal;
				font-weight: bold;
				font-size: 21px;
				line-height: 24px;
				color: var(--gray-900);
				margin: 0;
				margin-bottom: 18px;
			}

			.customer-inquiry-paragraph {
				margin: 0;
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 18px;
				color: var(--gray-700);
			}
		}

		.customer-inquiry-form {
			.input-container:last-of-type {
				margin: 0;
				margin-bottom: 8px;
			}

			.inquiry-content-count {
				font-style: normal;
				font-weight: normal;
				font-size: 14px;
				line-height: 22px;
				text-align: right;
				letter-spacing: -0.3px;
				color: #a5a9b2;
				margin: 0;
			}
		}
	}

	.button-area {
		display: flex;
		align-items: center;
		justify-content: center;
		position: fixed;
		background: rgba(17, 44, 75, 0.2);
		width: 100%;
		max-width: 360px;
		height: 60px;
		bottom: 0;
		cursor: pointer;
		user-select: none;
		margin-top: 24px;

		&.mobile {
			width: 100%;
			max-width: unset;
		}

		&.valid {
			background: var(--voucher-primary-color);
		}

		&.invalid {
			cursor: unset;
		}

		.inquiry-button {
			cursor: pointer;
			font-weight: 600;
			font-size: 18px;
			line-height: 22px;
			display: flex;
			align-items: center;
			text-align: center;
			color: var(--voucher-white-color);
			background-color: transparent;
			border: 0;
		}

		.loader-container {
			width: 100%;
			height: 60px;
		}
	}
}
