.facility-container {
	padding: 0 1rem 0;
	margin-bottom: 1.1875rem;
	.facility-item-list {
		display: flex;
		align-items: center;

		.facility-item {
			width: 25%;
		}
	}
}
