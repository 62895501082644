.button-container-placeholder {
	height: 84px;
	width: 100%;
}

.loader-container {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}

.disabled-pickup {
	border-top: 1px solid #e6e6e6;
	display: flex;
	justify-content: center;
	align-items: center;
	padding: 16px 0;
	font-weight: 600;
}
