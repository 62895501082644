.business-information-container {
	top: 0;
	position: fixed;
	z-index: 10;
	background: white;
	max-height: 100vh;
	overflow-y: scroll;
	text-align: left;
	margin: 0 auto;
	max-width: var(--max-width);
	box-sizing: border-box;
	width: 100%;
	left: 0;
	right: 0;
	min-height: 100vh;

	.header {
		position: sticky;
		top: 0;
		height: 40px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #ffffff;
		border-bottom: 3px solid #005baa;

		.close-button {
			position: absolute;
			right: 0;
			cursor: pointer;

			svg {
				height: 13px;
				vertical-align: middle;
				margin: auto;
			}
		}

		.close-title {
			font-size: 1rem;
			font-weight: 700;
		}
	}

	.business-information {
		color: #555;
		font-size: 15px;
		line-height: 1.6em;
		word-break: break-all;

		.business-information-table {
			width: 100%;
			border-collapse: collapse;
			margin: 0;

			caption {
				height: 1px;
				font-size: 0;
				line-height: 0;
			}

			tbody {
				margin: 0;
				padding: 0;
				border: 0 none transparent;
				font-size: inherit;

				th {
					width: 20%;
					padding: 15px 10px;
					border-bottom: 1px solid #d9d9d9;
					background: #f8f8f8;
					font-weight: 600;
					text-align: center;
				}

				td {
					padding: 15px 10px 15px 25px;
					border-bottom: 1px solid #d9d9d9;
				}
			}
		}

		.business-box {
			position: relative;
			padding-left: 30px;
			border-top-width: 0;
			border-bottom-width: 0;
			background-color: #f6f6f6;
			min-height: 50px;
			margin-top: 10px;
			padding-top: 20px;
			padding-right: 30px;
			padding-bottom: 20px;
			border-top-style: solid;
			border-top-color: #232c3b;
			border-bottom-style: solid;
			border-bottom-color: #dde1e2;

			.description {
				margin: 0;
				padding: 0;
				border: 0 none transparent;
				font-size: inherit;

				strong {
					font-weight: 600;
				}

				a {
					&:link {
						color: #636363;
						text-decoration: none;
					}
					&:hover {
						color: #0030ae;
						text-decoration: underline;
					}
				}

				.em_blue {
					color: #0861bc;
					font-style: normal;
				}
			}
		}
	}
}
