.menu-price-container {
	display: flex;
	justify-content: space-between;
	padding: 20px 16px;
	border-bottom: 1px solid #f1f1f1;
	.label {
		font-size: 14px;
		line-height: 17px;
		color: var(--gray-800);
		font-weight: bold;
	}

	.value {
		font-size: 14px;
		line-height: 17px;
		color: var(--gray-800);
		font-weight: bold;
	}
}
