.non-user {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	text-align: center;
	font-weight: 600;
	font-size: 20px;
}
.loader-container {
	display: flex;
	height: 100%;
	align-items: center;
	justify-content: center;
}
