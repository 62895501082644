.mall-title-container {
	position: relative;
	margin: 1.5rem 1rem 2rem;

	.title {
		height: auto;
		text-align: left;
		margin-bottom: 0.125rem;
		display: flex;
		justify-content: flex-start;
	}
	.info-discount {
		color: var(--tertiary-text-color);
		border-radius: 0.5rem;
		background: var(--quaternary-color);
		margin-left: 0.5rem;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 21px;
		padding: 0.125rem 0.375rem;
	}

	.description {
		display: block;
		text-align: left;
		margin-top: 0.875rem;
	}
	.mall-food {
		margin-bottom: 0.25rem;
	}
}
