.address-container {
	display: flex;
	flex-direction: column;
	margin: 0 20px 40px;

	& > h1 {
		@include title_16_m2;
		margin: 0 0 16px;
		color: #242629;
		user-select: none;
	}

	& > #map-container {
		height: 164px;
		margin-bottom: 8px;
		border-radius: 5px;
	}

	& > address {
		padding: 13px 16px;
		background-color: #f8f9fa;
		border-radius: 5px;
		font-style: inherit;

		.address-detail {
			@include small_2;
			margin: 0;
			color: #3c3f44;

			&:last-of-type {
				margin: 4px 0 0;
				color: #6c717a;
			}
		}

		.call-box {
			display: flex;
			align-items: center;
			margin-top: 12px;

			& > svg {
				width: 16px;
				height: 16px;
				margin-right: 4px;
			}

			& > a {
				font-weight: 400;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: -0.03em;
				color: #1f69ff;
			}
		}
	}
}
