@import 'pages/Booking/Incoming/Incoming';
@import 'pages/Booking/Info/Info';

// Global Partner Booking Step StyleSheets
.global {
	// Pages
	@import 'pages/Booking/Complete/Complete';
	@import 'pages/Booking/DetailReservationInfo/DetailReservationInfo';
	@import 'pages/Pickup/Pickup/Pickup';

	// Components
	@import 'Components/Share/Header/Header';
	@import 'Components/Share/Seperator';
	@import 'Components/Share/ThinSeparator';
	@import 'Components/Share/Booking/BookingFooter/BookingFooter';
	@import 'Components/Share/Booking/BookingSubtitle/BookingSubtitle';
	@import 'Components/Share/Booking/DepositNotice/DepositNotice';
	@import 'Components/Share/Booking/Timer/Timer';
	@import 'Components/Share/Subtitle';
	@import 'Components/Share/InputHint';
	@import 'Components/Share/NavigationModal/NavigationModal';
	@import 'Components/Share/Booking/RefundPolicy/RefundPolicy';
	@import 'Components/Share/Booking/Address/Address';
	@import 'Components/Booking/Info/PartySizeSelector/PartySizeSelector';
	@import 'Components/Booking/Info/Calendar/Calendar';
	@import 'Components/Booking/Info/Calendar/DateSelector';
	@import 'Components/Booking/Info/TimeSelector/TimeSelector';
	@import 'Components/Booking/BookingInfoView/BookingInfoView';
	@import 'Components/Booking/Category/CategorySelector/CategorySelector';
	@import 'Components/Booking/Category/DepositNotice/DepositNotice';
	@import 'Components/Booking/DetailInfo/UserInfoForm/UserInfoForm';
	@import 'Components/Booking/DetailInfo/DetailPartySize/DetailPartySize';
	@import 'Components/Booking/DetailInfo/MenuSelector/MenuSelector';
	@import 'Components/Booking/DetailInfo/AdditionalInfoForm/AdditionalInfoForm';
	@import 'Components/Booking/DetailInfo/RequestForm/RequestForm';
	@import 'Components/Booking/Payment/ReservationPreview/ReservationPreview';
	@import 'Components/Booking/Payment/SaleNotice/SaleNotice';
	@import 'Components/Booking/Payment/PaymentType/PaymentType';
	@import 'Components/Booking/Payment/PaymentForm/PaymentForm';
	@import 'Components/Booking/Payment/Agreement/Agreement';
	@import 'Components/Booking/Complete/ReservationInfo/ReservationInfo';
	@import 'Components/Booking/Complete/UserInfo/UserInfo';
	@import 'Components/Booking/DetailReservationInfo/Address/Address';
	@import 'Components/Booking/DetailReservationInfo/MallInfo/MallInfo';
	@import 'Components/Booking/DetailReservationInfo/RefundModal/RefundModal';
	@import 'Components/Pickup/Pickup/MallLocation';
	@import 'Components/Pickup/MenuDetail/MenuOption.scss';
	@import 'Components/Pickup/OrderSheet/OrderSheetFooter';
	@import 'Components/Pickup/Order/MallPickupDescription';
	@import 'Components/Pickup/Order/OrderMemo';
	@import 'Components/Pickup/Order/Agreement';
	@import 'Components/Pickup/Order/PaymentButton';
	@import 'Components/Pickup/Complete/PickupInfo';
	@import 'Components/Pickup/Complete/UserInfo';
}

.gyeongjuro {
	// Preset
	@import 'assets/styles/font';
	@import 'assets/styles/typography';
	
	// Pages
	@import 'pages/Booking/Complete/Complete_Gyeongjuro';
	@import 'pages/Booking/DetailReservationInfo/DetailReservationInfo_Gyeongjuro';
	@import 'pages/Pickup/Pickup/Pickup_Gyeongjuro';

	// Components
	@import 'Components/Gyeongju/Header/Header';
	@import 'Components/Gyeongju/Radio/Radio';
	@import 'Components/Gyeongju/Toast/Toast';
	@import 'Components/Share/Seperator_Gyeongjuro';
	@import 'Components/Share/ThinSeparator_Gyeongjuro';
	@import 'Components/Share/Booking/BookingFooter/BookingFooter_Gyeongjuro';
	@import 'Components/Share/Booking/BookingSubtitle/BookingSubtitle_Gyeongjuro';
	@import 'Components/Share/Booking/DepositNotice/DepositNotice_Gyeongjuro';
	@import 'Components/Share/Booking/Timer/Timer_Gyeongjuro';
	@import 'Components/Share/Subtitle_Gyeongjuro';
	@import 'Components/Share/InputHint_Gyeongjuro';
	@import 'Components/Share/NavigationModal/NavigationModal_Gyeongjuro';
	@import 'Components/Share/Booking/RefundPolicy/RefundPolicy_Gyeongjuro';
	@import 'Components/Gyeongju/Address/Address';
	@import 'Components/Booking/Info/PartySizeSelector/PartySizeSelector_Gyeongjuro';
	@import 'Components/Booking/Info/Calendar/Calendar_Gyeongjuro';
	@import 'Components/Booking/Info/Calendar/DateSelector_Gyeongjuro';
	@import 'Components/Booking/Info/TimeSelector/TimeSelector_Gyeongju';
	@import 'Components/Booking/BookingInfoView/BookingInfoView_Gyeongjuro';
	@import 'Components/Booking/Category/CategorySelector/CategorySelector_Gyeongjuro';
	@import 'Components/Booking/Category/DepositNotice/DepositNotice_Gyeongjuro';
	@import 'Components/Booking/DetailInfo/UserInfoForm/UserInfoForm_Gyeongjuro';
	@import 'Components/Booking/DetailInfo/DetailPartySize/DetailPartySize_Gyeongjuro';
	@import 'Components/Booking/DetailInfo/MenuSelector/MenuSelector_Gyeonguro';
	@import 'Components/Booking/DetailInfo/RequestForm/RequestForm_Gyeongjuro';
	@import 'Components/Booking/Payment/ReservationPreview/ReservationPreview_Gyeongjuro';
	@import 'Components/Gyeongju/PaymentType/PaymentType';
	@import 'Components/Gyeongju/Agreement/Agreement';
	@import 'Components/Booking/Complete/ReservationInfo/ReservationInfo_Gyeongjuro';
	@import 'Components/Booking/Complete/UserInfo/UserInfo_Gyeongjuro';
	@import 'Components/Booking/DetailReservationInfo/MallInfo/MallInfo_Gyeongjuro';
	@import 'Components/Booking/DetailReservationInfo/RefundModal/RefundModal_Gyeongjuro';
	@import 'Components/Pickup/Pickup/MallLocation_Gyeongjuro';
	@import 'Components/Pickup/MenuDetail/MenuOption_Gyeongjuro';
	@import 'Components/Pickup/OrderSheet/OrderSheetFooter_Gyeongjuro';
	@import 'Components/Pickup/Order/MallPickupDescription_Gyeongjuro';
	@import 'Components/Pickup/Order/OrderMemo_Gyeongjuro';
	@import 'Components/Pickup/Order/Agreement_Gyeongjuro';
	@import 'Components/Pickup/Order/PaymentButton_Gyeongjuro';
	@import 'Components/Pickup/Complete/PickupInfo_Gyeongjuro';
	@import 'Components/Pickup/Complete/UserInfo_Gyeongjuro';
	

	// Styles
	* {
		font-family: 'Noto Sans', 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto,
			'Helvetica Neue', 'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji',
			'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
		-webkit-tap-highlight-color : transparent;
	}

	color: var(--gray-900);
}

.main-container-desktop {
	width: 100%;
	max-width: 360px;
	background-color: white;
	position: relative;
}

.main-container-mobile {
	width: 100%;
	max-width: 100%;
	background-color: white;
}

.date-time-picker-container, .gyeongju-date-time-picker-container {
	.MuiInputBase-root {
		opacity: 0;
		z-index: 0;
		width: 0;
		position: absolute;
		top: 16px;
	}
}

.billing-form-container,
.payment-form-container {
	.MuiInput-underline:before {
		border: none;
	}
	.MuiFormLabel-filled {
		display: none;
	}
}

.additional-info-form-container {
	.MuiSelect-icon {
		display: none;
	}
	.MuiInput-underline:before {
		display: none;
	}
	.MuiInput-underline:after {
		display: none;
	}
	.MuiSelect-select:focus {
		background-color: transparent;
	}
}
*::-webkit-scrollbar {
	display: none !important;
	width: 0 !important;
	height: 0;
	background: transparent;
}

.carousel-container {
	.carousel {
		&.carousel-slider {
			.control-arrow {
				top: calc(50% - 14px);
				display: flex;
				align-items: center;
				justify-content: center;
				height: 28px;
			}
		}
		.control-dots {
			z-index: 5;
			.dot {
				opacity: 0.5;
				box-shadow: none;
			}
		}
	}
}

.Mui-checked > span.MuiIconButton-label > svg > rect {
	fill: var(--primary-color) !important;
}

.Mui-checked:not(.all-check) > span.MuiIconButton-label > svg > path {
	stroke: var(--primary-color) !important;
}

.booking-subtitle-container > .move > svg > path {
	stroke: var(--primary-color) !important;
}

.title > div > svg > path {
	stroke: var(--primary-color) !important;
}

.Toastify__toast {
	text-align: center;
}
.Toastify__toast-container--bottom-center {
	bottom: 3.25rem !important;
}
.Toastify__close-button {
	&.Toastify__close-button--dark {
		display: none;
	}
}

.radio-box {
	.icon {
		width: 1.5rem;
		height: 1.5rem;
	}
}
.Mui-checked > span.MuiIconButton-label > svg > circle:nth-child(1) {
	fill: var(--primary-color);
}

input,
textarea {
	appearance: none;
}
