.header-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 48px;
	position: fixed;
	width: 100%;
	max-width: var(--max-width);
	top: 0;
	z-index: 10;
	background: white;

	&.voucher-header {
		align-items: center;

		.center {
			.logo {
				height: 26px;
				cursor: pointer;
			}
		}
	}

	&.fixed {
	}
	&.absolute {
		background: transparent;
	}
	.left {
		min-width: 56px;
		height: 100%;
		display: flex;
		align-items: center;
		user-select: none;
		pointer-events: none;

		svg {
			pointer-events: auto;
			padding: 6px;
		}

		.back {
			width: 10px;
			height: 18px;
			margin-left: 12px;
			cursor: pointer;

			&.white {
				width: 24px;
				height: 24px;
				margin-left: 5px;
			}
		}
		.hamburger {
			width: 16px;
			height: 16px;
			margin-left: 12px;
		}
		.close {
			width: 16px;
			height: 16px;
			margin-right: 12px;
		}
		.menu {
			width: 26px;
			height: 26px;
			margin-left: 10px;
			cursor: pointer;
		}
	}

	.center {
		width: 100%;
		text-align: center;
		svg {
			//width: 95px;
			//height: 15px;
		}

		.logo {
			height: 26px;
			cursor: pointer;
		}
		font-weight: bold;
	}

	.right {
		min-width: 56px;
		height: 100%;
		display: flex;
		align-items: center;
		user-select: none;

		svg {
			width: 16px;
			height: 16px;
			margin-right: 12px;
			padding: 6px;
			pointer-events: auto;

			&.home {
				width: 24px;
				height: 24px;
				margin: 0 8px;
			}
			&.locale {
				width: 20px;
				height: 20px;
				margin: 12px;
				position: absolute;
				right: 44px;
				& + ul {
					display: none;
				}
				&.localeActive + ul {
					display: block;
					position: absolute;
					right: 48px;
					top: 48px;
					background-color: #ffffff;
					width: 100px;
					list-style: none;
					padding: 0;
					margin: 0;
					box-shadow: 0 2px 5px 2px rgba(0, 0, 0, 0.5);
					& > li {
						display: flex;
						height: 40px;
						align-items: center;
						padding: 0 10px;
						&:hover, &:active {
							background-color: #eeeeee;
						}
					}
				}
			}
			&.share {
				width: 24px;
				height: 24px;
				margin: 10px;
			}
			&.my-page {
				width: 26px;
				height: 26px;
				cursor: pointer;
			}
			&.close {
				cursor: pointer;
			}
		}
	}

	.half-width {
		width: 50%;

		&.right {
			justify-content: flex-end;
		}
	}
}

.show {
	display: flex;
}
.hide {
	display: none;
}

.header-container-placeholder {
	height: 48px;
	width: 100%;
}
.my-reservation-button {
	outline: none;
	background: transparent;
	padding: 4px 12px;
	border-radius: 12px;
	color: white;
	border: white solid 1px;
	position: absolute;
	top: 12px;
	right: 12px;
	z-index: 10;
}
