.card-list-container {
	display: flex;
	align-items: center;
	overflow: auto;
	width: calc(100% + 32px);
	margin-left: -16px;

	.pay-card {
		display: flex;
		align-items: center;
		justify-content: center;
		flex-direction: column;
		min-width: 12rem;
		height: 8rem;
		padding: 0 1rem;
		margin: 1rem 0.5rem;
		font-weight: 200;
		border-radius: 10px;
		background: #1d1f5c;
		font-weight: 700;
		cursor: pointer;

		&.add-card-button {
			background: #8a8a8a;

			.add-card-icon {
				width: 24px;
			}

			.card-text {
				text-align: center;
				padding-left: 0;
				color: #646464 !important;
			}
		}

		&.selected-card {
			align-items: flex-start;
			background: #2e3192;
			position: relative;

			.card-text {
				color: white !important;
			}
		}

		&.unselected-card {
			align-items: flex-start;
			background: #1d1f5c;

			.card-text {
				color: white !important;
			}
		}

		.delete-icon-wrapper {
			display: flex;
			align-items: center;
			justify-content: center;
			position: absolute;
			height: 1rem;
			width: 1rem;
			top: 0.5rem;
			right: 2rem;
			background-color: white;
			border-radius: 100%;
			margin: 0;
			padding: 0;
			border: 0;
			outline: none;
			cursor: pointer;

			.delete-icon {
				height: 0.5rem;
				width: 0.5rem;
			}
		}

		.card-text {
			width: 100%;
			text-align: left;
			color: #a2a2a2;
			white-space: nowrap;
		}

		.card-kind {
			text-align: left;
		}

		.checkbox-image {
			height: 1rem;
			position: absolute;
			top: 0.5rem;
			right: 0.5rem;
		}
	}
}
