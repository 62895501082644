.menu-option-container {
	padding: 24px 16px;
	border-bottom: 1px solid #f1f1f1;
	.menu-option-name {
		font-size: 14px;
		line-height: 17px;
		font-weight: bold;
		margin-bottom: 24px;
		.required {
			color: #ff2222;
		}
	}
}
