.service-container {
	top: 0;
	position: fixed;
	z-index: 10;
	background: white;
	max-height: 100vh;
	overflow-y: scroll;
	text-align: left;
	margin: 0 auto;
	padding: 0 16px;
	max-width: var(--max-width);
	box-sizing: border-box;
	width: 100%;
	left: 0;
	right: 0;
	min-height: 100vh;

	.header {
		display: flex;
		justify-content: space-between;
		margin: 10px 8px;
	}

	.service-contents {
		margin-bottom: 12px;

		.service-title {
			font-size: 15px;
			font-weight: bold;
			margin: 0;
		}

		.service-content {
			font-size: 13px;
			line-height: 21px;
			word-break: break-all;
			text-align: left;
			display: flex;
			margin: 0;
			margin-right: 6px;
		}

		.service-table {
			text-align: center;
			width: 100%;
			border: 1px solid #444444;
			border-collapse: collapse;

			th,
			td {
				font-weight: unset;
				border: 1px solid #444444;

				.pre-line-paragraph {
					white-space: pre-line;
					margin: 0;
					text-align: left;
					padding-left: 6px;
				}
			}
		}

		.service-items {
			font-size: 13px;
			line-height: 21px;
			word-break: break-all;
			text-align: left;
			margin: 0;
			counter-reset: item;
			list-style-type: none;
			padding-left: 15px;

			&.static-numbering {
				li {
					list-style: none;
					counter-increment: unset;
					margin-left: 0.2em;

					&:before {
						content: unset;
					}

					.item-number {
						display: block;
						margin-right: 6px;
					}
				}
			}

			&[start].auto-numbering {
				li {
					counter-increment: item;
					margin-left: 0.2em;

					&:before {
						content: counter(item) '.';
						float: left;
						width: 1em;
						margin-right: 3px;
					}

					.static-numbering {
						li {
							list-style: none;
							counter-increment: unset;
							margin-left: 0.2em;

							&:before {
								content: unset;
							}

							.item-number {
								display: block;
								margin-right: 6px;
							}
						}
					}
				}
			}

			.service-table,
			.service-content {
				margin-bottom: 3px;
			}

			&:first-child {
				counter-reset: item;
			}
		}
	}
}

.close-button {
	svg {
		height: 14px;
		vertical-align: middle;
		margin: auto;
	}
}

.close-title {
	font-size: 1rem;
	font-weight: 700;
}
