.toast-popup {
	user-select: none;

	&.mobile {
		.MuiSnackbar-root {
			left: auto;
			right: auto;
		}
	}

	.MuiSnackbar-root {
		display: flex;
		align-items: inherit;
		justify-content: center;
		min-width: 328px;
		width: 100%;
		height: 50px;
		top: 62px;
		border-radius: 5px;
		padding: 0;
		z-index: 10;
		position: fixed;

		.MuiSnackbarContent-root {
			background: var(--black-200);
			justify-content: center;
			margin: 0 16px;

			.MuiSnackbarContent-message {
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 18px;
				text-align: center;
				color: var(--voucher-white-color) !important;
			}
		}
	}
}
