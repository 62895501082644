.gyeongjuro-counter-option-container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 16px 0;
	.option-name {
		font-size: 16px;
		line-height: 21px;
		color: #242629;
		.option-price {
			color: #6C717A;
		}
	}
	.counter-container {
		display: flex;
		justify-content: space-between;
		svg {
			width: 20px;
			height: 20px;
			padding: 6px;
			border: 1px solid #CFD3D9;
			&.minus {
				border-radius: 2px 0 0 2px;	
			}
			&.plus {
				border-radius: 0 2px 2px 0;	
			}
		}
		.count {
			height: 32px;
			width: 42px;
			color: var(--gray-800);
			font-size: 14px;
			line-height: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			border-top: 1px solid #CFD3D9;
			border-bottom: 1px solid #CFD3D9;
		}
	}
}
