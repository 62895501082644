.reservation-info-container {
	padding: 0 1rem;
	margin-bottom: 3rem;
	.mall-name {
		margin-bottom: 3.125rem;
	}
	.info-box {
		margin-bottom: 0.375rem;
		text-align: left;
		display: flex;

		& > div {
			display: inline-block;
		}

		.label {
			width: 70px;
			color: var(--gray-600);
		}
	}
	.complete-box-placeholder {
		width: 100%;
		height: 76px;
	}
	.complete-box {
		color: var(--accent-color);
		margin-top: 2.5rem;
		margin-bottom: 0.25rem;
	}
}
