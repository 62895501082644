.gyeongju-radio-component {
	width: fit-content;
	display: flex;
	align-items: center;
	cursor: pointer;
	user-select: none;

	& > input[type='radio'] {
		width: 0;
		height: 0;
		margin: 0;
	}

	.default-label {
		@include body_14_2;
		margin-left: 14px;
		line-height: initial;
	}
}
