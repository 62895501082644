.order-sheet-header-container {
	display: flex;
	justify-content: space-between;
	padding: 16px 16px;
	border-bottom: 1px solid #f1f1f1;

	.mall-name {
		color: var(--gray-800);
		font-size: 16px;
		line-height: 20px;
		font-weight: bold;
	}

	.clear-button {
		font-size: 13px;
		line-height: 16px;
	}
}
