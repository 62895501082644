.calendar-container {
	padding: 0 1rem;
	&:not(.isLoaded) {
		display: none;
	}

	.picker-container {
		.week-list {
			display: flex;
			flex-wrap: wrap;
			margin-top: 1rem;
			justify-content: space-between;

			& > * {
				font-size: 0.875rem;
				display: flex;
				justify-content: center;
				min-width: 1.125rem;
				text-align: center;
				color: #464647;
				border: 0.0625rem solid transparent;
				padding: 9px;

				*.text {
					color: var(--gray-200);
				}

				&.hide {
					opacity: 0;
					cursor: default;
				}

				&.deactive {
					color: rgba(51, 51, 51, 0.15) !important;
				}

				&.selected {
					margin: 0;
					border: 0.0625rem solid var(--primary-color);
					background: var(--primary-alpha-color);
					border-radius: 0.25rem;
					color: var(--gray-900);
				}

				&:nth-of-type(7n + 1) {
					color: var(--functional-color-negative);
				}

				&:nth-of-type(7n + 7) {
					color: var(--accent-color);
				}
			}
		}
	}

	.no-item-noti {
		height: 308px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 40px 0 0;
		text-align: center;
		word-break: keep-all;
		font-weight: 400;
		font-size: 16px;
		letter-spacing: -0.01em;
		color: var(--gray-900);
	}
}
