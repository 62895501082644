.gyeongjuro-menu-confirm-container {
	padding: 20px 0 0;
	position: fixed;
	bottom: 0;
	width: 100%;
	max-width: var(--max-width);
	box-sizing: border-box;
	background: white;
	border-top: 1px solid #E0E4EB;
	.count-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
		padding: 0 16px;
		.icon-container {
			display: flex;
			align-items: center;

			.icon {
				height: 34px;
				svg {
					width: 20px;
					height: 20px;
					padding: 6px;
					border: 1px solid #CFD3D9;
					&.minus {
						border-radius: 2px 0 0 2px;	
					}
					&.plus {
						border-radius: 0 2px 2px 0;	
					}
				}
			}

			.count {
				height: 34px;
				width: 42px;
				color: var(--gray-800);
				font-size: 14px;
				line-height: 18px;
				display: flex;
				align-items: center;
				justify-content: center;
				border-top: 1px solid #CFD3D9;
				border-bottom: 1px solid #CFD3D9;
				box-sizing: border-box;
			}
		}

		.price-container {
			font-size: 18px;
			line-height: 23px;
			color: var(--gray-800);
			font-weight: 800;
		}
	}
}

.menu-confirm-container-placeholder {
	height: 132px;
	width: 100%;
}
