.party-size-selector-container {
	height: 24px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	margin: 20px 20px 0;
	user-select: none;

	&:not(.isLoaded) {
		display: none;
	}

	.people-text {
		font: {
			weight: 500;
			size: 16px;
		}

		line-height: 18px;
		letter-spacing: -0.3px;
		word-spacing: initial;
	}
	
	.icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		
		.icon {
			height: 24px;
			
			svg {
				cursor: not-allowed;

				&.enable {
					cursor: pointer;

					path {
						stroke: #52a1ff;
					}
				}
			}
		}
		
		.people-value {
			width: 22px;
			margin: 0 8px;
			text-align: center;
			font: {
				weight: 500;
				size: 16px;
			}

			line-height: initial;
			word-spacing: initial;
		}
	}
}
