.checkbox-container {
	display: flex;
	flex-direction: column;

	.checkbox-box {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
}
