.telephone-anchor {
	text-decoration: none;

	.telephone-inquiry-button {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		height: 48px;
		padding: 0;
		border: 0;
		border-radius: 5px;
		box-shadow: 0 10px 10px 0 rgba(0, 0, 0, 0.05);
		background-color: #ffffff;
		cursor: pointer;

		.telephone-icon {
			width: 16px;
			height: 16px;
			background: url(/assets/icon/emoji_phone.png);
			background-size: cover;
		}

		.text {
			height: 17px;
			flex-grow: 0;
			margin: 0 0 0 8px;
			font-size: 14px;
			font-weight: 600;
			font-stretch: normal;
			font-style: normal;
			line-height: normal;
			letter-spacing: normal;
			text-align: left;
			color: var(--voucher-primary-color);
		}
	}
}
