@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;600;700&display=swap');

body {
	margin: 0;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	color: var(--gray-800);

	* {
		font-family: 'Pretendard Variable', Pretendard, -apple-system, BlinkMacSystemFont, system-ui, Roboto, 'Helvetica Neue',
		'Segoe UI', 'Apple SD Gothic Neo', 'Noto Sans KR', 'Malgun Gothic', 'Apple Color Emoji', 'Segoe UI Emoji',
		'Segoe UI Symbol', sans-serif;
	}

	&.modal-open {
		overflow: hidden;
	}
	.t1 {
		font-size: 22px;
		word-spacing: -0.02em;
		color: var(--gray-900);
		font-weight: 800;
		line-height: 28px;
	}
	.t2 {
		font-size: 20px;
		word-spacing: -0.02em;
		color: var(--gray-900);
		font-weight: 700;
		line-height: 25px;
	}
	.t3 {
		font-size: 18px;
		word-spacing: -0.02em;
		color: var(--gray-900);
		font-weight: 600;
		line-height: 23px;
	}
	.t4 {
		font-size: 16px;
		word-spacing: -0.01em;
		color: var(--gray-900);
		font-weight: 600;
		line-height: 21px;
	}
	.t5 {
		font-size: 15px;
		word-spacing: -0.01em;
		color: var(--gray-900);
		font-weight: 500;
		line-height: 20px;
	}
	.b1 {
		font-size: 14px;
		word-spacing: -0.01em;
		color: var(--gray-800);
		line-height: 18px;
	}
	.b2 {
		font-size: 13px;
		word-spacing: -0.01em;
		color: var(--gray-800);
		line-height: 17px;
	}
	.b3 {
		font-size: 12px;
		word-spacing: -0.01em;
		color: var(--gray-600);
		line-height: 15px;
	}
	&.android {
		line-height: 1.5;
		.t1 {
			line-height: 32px;
		}
		.t2 {
			line-height: 30px;
		}
		.t3 {
			line-height: 27px;
		}
		.t4 {
			line-height: 24px;
		}
		.t5 {
			line-height: 22px;
		}
		.b1 {
			line-height: 21px;
		}
		.b2 {
			line-height: 19px;
		}
		.b3 {
			line-height: 18px;
		}
	}
	&.ios {
		line-height: 1.3;
	}
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
