.user-info-container {
	padding: 0 16px;
	.subtitle {
		flex-direction: row;
		font-size: 18px;
		font-weight: 600;
		line-height: 23px;
		letter-spacing: -0.36px;
		margin-bottom: 20px;
	}
	.row {
		display: flex;
		margin-bottom: 6px;
		.label {
			min-width: 60px;
			font-size: 14px;
			line-height: 20px;
			margin-right: 20px;
			color: #858B93;
		}

		.value {
			font-size: 14px;
			line-height: 20px;
			color: #242629;
		}
	}
}
