.mall-location-container {
	margin: 24px 0 18px;
	padding: 0 16px 18px;
	border-bottom: 1px solid #e6e6e6;
	.header-text {
		color: #242629;
		font-size: 18px;
		font-style: normal;
		font-weight: 600;
		line-height: 23px; /* 127.778% */
		letter-spacing: -0.36px;
		margin-bottom: 40px;
	}
	#map-container {
		border-radius: 8px;
		height: 160px;
	}
	.detail-info-container {
		margin-top: 10px;
		border-radius: 8px;
		background: var(--gray-0, #F8F9FA);
		padding: 20px 16px;
		.address-text {
			color: #242629;
			font-size: 14px;
			line-height: 18px;
			letter-spacing: -0.28px;
		}
	}
}
