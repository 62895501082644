.payment-agreement-container {
	display: flex;
	flex-direction: column;
	user-select: none;
	margin: 0 20px 72px;

	& > h1 {
		@include title_18_m;
		margin: 0 0 16px;
	}

	& > label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 13px 24px 13px 20px;
		border-radius: 8px;
		cursor: pointer;
		font-weight: 400;
		font-size: 14px;
		line-height: 18px;
		letter-spacing: -0.0003em;

		&.all-selector {
			@include body_14_m;
			background-color: #f1f2f3;
		}

		&:not(:last-of-type) {
			margin-bottom: 4px;
		}

		& > div {
			display: flex;
			align-items: center;
			
			svg {
				padding: 2px 8px;
			}
		}
		
		& > input[type='checkbox'] {
			display: none;
		}
	}

	& > ul {
		@include small_2;
		margin: 12px 0 0 20px;
		padding: 0;
		color: var(--gray-500);
	}
}
