.deposit-info-container {
	padding: 2rem 1rem 0;
	font-size: 0.875rem;
	line-height: 1.375rem;
	color: #464647;
	font-weight: 500;
	.warn {
		color: #ff360a;
	}
}
