.tab-container {
	display: flex;
	justify-content: space-evenly;
	align-items: center;
	border-bottom: 1px solid #e6e6e6;
	padding-bottom: 8px;
	margin-bottom: 24px;
	.tab {
		flex: 1;
		text-align: center;
		color: #8b8b8b;
		font-size: 15px;
		line-height: 18px;
		max-width: 70px;
		&.selected {
			padding-bottom: 8px;
			margin-bottom: -8px;
			border-bottom: 4px solid #0075ff;
			font-weight: 800;
			color: #0075ff;
		}
	}
}
