.gyeongjuro-menu-price-container {
	display: flex;
	justify-content: space-between;
	padding: 20px 16px;
	border-bottom: 8px solid #F1F2F3;
	.label {
		font-size: 18px;
		line-height: 23px;
		color: #242629;
		letter-spacing: -0.36px;
		font-weight: bold;
	}

	.value {
		font-size: 18px;
		line-height: 21px;
		color: var(--gray-800);
		font-weight: bold;
		letter-spacing: -0.18px;
	}
}
