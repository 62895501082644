.timer-container {
	* {
		color: var(--quaternary-color) !important;
	}

	p {
		margin: 0;
	}

	& > div {
		display: flex;
		justify-content: center;
		align-items: center;
		background-color: var(--quaternary-color-alpha-05);

		&.timer-box {
			margin: 0 1rem 3.125rem;
			padding: 0.75rem 1.5rem;
			border-radius: 0.5rem;

			.time {
				width: 15%;
				margin-right: 10%;
				font-weight: 500;
			}
		}

		&.timer-mini {
			width: 100%;
			max-width: var(--max-width);
			height: 2.0625rem;
			position: fixed;
			top: 2.75rem;
			z-index: 10;

			&.none-header {
				top: 0;
			}
		}
	}
}
