.payment-action-container {
	border-top: 1px solid #e6e6e6;
	padding: 16px;
	background-color: white;
	// position: fixed;
	bottom: 0;
	width: 100%;
	max-width: var(--max-width);
	box-sizing: border-box;
}

.payment-action-container-placeholder {
	height: 85px;
	width: 100%;
}
