.voucher-info-container {
	display: flex;
	flex-direction: column;
	padding: 16px;
	margin: 40px 32px;
	border-radius: 8px;
	box-shadow: 0 13.1006px 26.2012px -13.1006px rgba(24, 39, 75, 0.12),
		0 17.4675px 52.4024px -8.73373px rgba(24, 39, 75, 0.08);
	border: 1px solid #e6e6e6;
	height: 180px;
	.voucher-name {
		font-size: 14px;
		font-weight: 500;
	}

	.voucher-box {
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		height: 100%;
		.voucher-amount {
			text-align: center;
			font-weight: 600;
			font-size: 20px;
			line-height: 24px;
			margin-bottom: 10px;
		}
		.voucher-amount-text {
			font-size: 13px;
			line-height: 24px;
		}
	}
}
