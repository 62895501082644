.mall-phone-container {
	display: flex;
	margin-top: 0.75rem;
	padding: 0 1rem;
	.icon-container {
		.call-icon {
			width: 1.125rem;
			height: 1.125rem;
			margin-right: 0.75rem;
		}
	}

	.phone {
		cursor: pointer;
		.tel {
			height: 0.875rem;
			line-height: 1.125rem;
			font-size: 0.875rem;
			font-weight: 400;
			color: var(--gray-800);
		}
	}
}
