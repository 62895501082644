.side-menu-container {
	position: fixed;
	top: 0;
	z-index: 50;
	width: 100%;
	max-width: var(--max-width);
	height: 100%;
	background: white;

	.side-header-container {
		padding: 20px;

		svg {
			width: 16px;
			height: 16px;
		}
	}

	.side-body-container {
		padding: 20px;
		font-size: 16px;
		font-weight: 500;
		.menu-container {
			.menu-item {
				margin-bottom: 36px;
			}
		}
	}
}
