.mypage-deposit-notice-container {
	padding: 2rem 1rem 4rem;
	.notice-box {
		margin-bottom: 3rem;
		.description {
			font-weight: 500;
			font-size: 0.875rem;
			line-height: 1.3125rem;
			color: #464647;
			letter-spacing: -0.0625rem;
			.noshow {
				color: #ff360a;
			}
		}
	}
}
