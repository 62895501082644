.pickup-user-info-form-container {
	padding: 0 16px;

	.label {
		font-size: 12px;
		line-height: 18px;
		margin-bottom: 4px;

		&.required {
			&:after {
				content: ' *';
				color: #FF471E;
			}
		}
	}

	.input {
		outline: none;
		border: 1px solid #D6D8DB;
		border-radius: 2px;
		width: 100%;
		font-size: 14px;
		line-height: 18px;
		padding: 13px;
		box-sizing: border-box;
		margin-bottom: 20px;

		&:last-child {
			margin-bottom: 0;
		}
		&:disabled {
			background: #F8F9FA;
			&::placeholder {
				color: #E9EBEE;
			}
		}
		
	}

	.input-button {
		outline: none;
		border: none;
		border-radius: 2px;
		font-size: 14px;
		line-height: 16px;
		box-sizing: border-box;
		margin-bottom: 18px;
		padding: 0;
		cursor: pointer;
		min-width: 120px;
		background-color: var(--primary-color);
		color: white;
		display: flex;
		justify-content: center;
		align-items: center;
		height: 46px;

		&:disabled {
			background: #ffffff;
			color: #E9EBEE;
			border: 1px solid #E9EBEE;
		}
	}

	.input-group {
		display: flex;
		gap: 16px;
		&.phone-number {
			.input {
				margin-bottom: 10px;
			}
			.input-button {
				margin-bottom: 10px;
			}
		}
		> .input {
			width: 100%;
		}

		> .input-button {
			border-radius: 2px;
		}
	}
}
